define('scientia-resourcebooker/pods/logged-in/booking-type/show/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {
    titleToken: function titleToken(model) {
      return model.bookingType.get('name') + ' — ' + this.get('i18n').t('pods.logged_in.booking_type.show.bookings');
    },

    nestedResourceStore: _ember['default'].inject.service(),
    bookingTypeResourceSearcher: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    recurrenceProcessor: _ember['default'].inject.service(),
    customEvents: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),

    queryParams: {
      spanMultipleDays: { replace: true, refreshModel: false },
      availableNow: { replace: true, refreshModel: false },
      firstDay: { replace: true, refreshModel: false },
      lastDay: { replace: true, refreshModel: false },
      duration: { replace: true, refreshModel: false },
      fromTime: { replace: true, refreshModel: false },
      toTime: { replace: true, refreshModel: false },
      query: { replace: true, refreshModel: false },
      resourceGroupId: { refreshModel: false },

      editingPrimary: { replace: true, refreshModel: false },
      bookingId: { replace: true, refreshModel: false },
      editOrigin: { replace: true, refreshModel: false },

      isAdminPrimaryResourceSearch: { replace: true, refreshModel: false },

      isCopyMode: { replace: true, refreshModel: false },

      concurrencyId: { replace: true, refreshModel: false }
    },

    customFilters: null,

    bookingType: null,
    resourceGroup: null,

    beforeModel: function beforeModel(transition) {
      var _this = this;

      this.get('customEvents').one('clearResourceFilters', function () {
        _this._resetControllerProperties();
      });

      // Redirect to My bookings page if we're editingPrimary but the booking hasn't been loaded (eg. user has refresh the page)
      if (transition.queryParams.editingPrimary) {
        var hasSecondaryControllerLoaded = this.controllerFor('logged-in.resource.show').get('model.bookingRequest');
        if (!hasSecondaryControllerLoaded) {
          this.transitionTo('logged-in.booking-request');
          return;
        }
      }

      this._createResourcePatternFragment(transition);
    },

    model: function model(params /*, transition*/) {
      var _this2 = this;

      this.controllerFor('logged-in.booking-type.show').set('isSearching', true);

      return _ember['default'].RSVP.hash({

        bookingType: this.get('bookingType') ? _ember['default'].RSVP.resolve(this.get('bookingType')) : this.store.findRecord('booking-type', params.id)['catch'](function () {
          _this2.transitionTo('logged-in.booking-type.index').then(function () {
            _this2.flashError(_this2.get('i18n').t('pods.logged_in.booking_type.show.error_not_found').toString(), 15000);
          });
        }),

        resources: [], // loaded in afterModel()
        resourceGroups: [], // loaded in afterModel()
        customFilters: this.get('customFilters'),

        resourceGroup: this._getResourceGroup(params)

      });
    },

    _getResourceGroup: function _getResourceGroup(params) {

      // Do we already have ther required group loaded? If so just return it
      if (params.resourceGroupId && this.get('resourceGroup') && this.get('resourceGroup.id') === params.resourceGroupId) {
        return _ember['default'].RSVP.resolve(this.get('resourceGroup'));

        // If we have a groupId but not group loaded, just fetch it
      } else if (params.resourceGroupId) {
          this.controllerFor(this.routeName).set('loadedIds', []);
          return this.get('nestedResourceStore').fetch({
            type: 'bookingType.resourceGroup',
            parentId: params.id,
            id: params.resourceGroupId
          });

          // Else don't return a group
        } else {
            return null;
          }
    },

    afterModel: function afterModel(model) {

      var controller = this.controllerFor('logged-in.booking-type.show');

      // Redirect if BT is adminOnly and user is not an admin
      if (!this._isBookingTypeAccessibleForUser(model.bookingType)) return;

      // Log pageview
      this.get('appInsights').trackPageView(this.get('routeName'), {
        name: model.bookingType.get('name'),
        id: model.bookingType.get('id')
      });

      this._setupBookingTypeNotifications(model.bookingType);
      this._loadCustomFields(model);

      controller.set('loadedIds', []);
      controller.get('filterResourcesAndGroups').perform(model.bookingType);
    },

    _loadCustomFields: function _loadCustomFields(model) {
      var _this3 = this;

      var resourceTypeId = model.bookingType.get('resourceRestrictionRules').filterBy('isPrimary').get('firstObject.resourceType.id');

      // If custom filters have already been fetched don't refetch everytime a filter is changed
      if (model.customFilters === null && resourceTypeId) {

        // Else fetch custom filters (resource type properties)
        this.get('nestedResourceStore').fetch({ type: 'bookingType.resourceType', parentId: model.bookingType.get('id'), isSingleResource: true }).then(function (resourceType) {
          var props = resourceType.get('properties');
          var filtered = props.filter(function (property) {
            return property.get('isFilterable') && !property.get('isObsolete');
          });

          _this3.set('customFilters', filtered);
          _ember['default'].set(model, 'customFilters', filtered);
        });
      }
    },

    _isBookingTypeAccessibleForUser: function _isBookingTypeAccessibleForUser(bookingType) {
      if (bookingType.get('mode') === '1' && !this.get('currentUser').isAdmin()) {
        this.transitionTo('logged-in.booking-type.index');
        return false;
      }
      return true;
    },

    _setupBookingTypeNotifications: function _setupBookingTypeNotifications(bookingType) {
      var controller = this.controllerFor('logged-in.booking-type.show');

      // Check if should display BookingType Alert
      if (!bookingType.get('alertEnabled')) {
        controller.set('bookingTypeAlert', null);
      } else {
        if (controller.get('bookingTypeAlert.bookingTypeId') !== bookingType.id || !controller.get('bookingTypeAlertIsDismissed')) {
          controller.set('bookingTypeAlert', {
            type: bookingType.get('alertType'),
            bookingTypeId: bookingType.get('id'),
            alert: true,
            message: _ember['default'].String.htmlSafe(bookingType.get('alertMessage'))
          });
        } else {
          controller.set('bookingTypeAlert', null);
        }
      }

      // Check if should display BookingType Info
      if (!bookingType.get('infoEnabled')) {
        controller.set('bookingTypeInfo', null);
      } else {
        if (controller.get('bookingTypeInfo.bookingTypeId') !== bookingType.id || !controller.get('bookingTypeInfoIsDismissed')) {
          controller.set('bookingTypeInfo', {
            type: bookingType.get('infoType'),
            bookingTypeId: bookingType.get('id'),
            info: true,
            message: _ember['default'].String.htmlSafe(bookingType.get('infoMessage'))
          });
        } else {
          controller.set('bookingTypeInfo', null);
        }
      }
    },

    _createResourcePatternFragment: function _createResourcePatternFragment() {
      var controller = this.controllerFor(this.routeName);

      var pattern = controller.get('recurrence');
      if (!pattern) {
        var newRecurrencePattern = this.store.createFragment('recurrence-pattern', {
          fromTime: null,
          toTime: null
        });
        controller.set('recurrence', newRecurrencePattern);
      }
    },

    _resetControllerProperties: function _resetControllerProperties() {

      var bookingTypeShowController = this.controllerFor('logged-in.booking-type.show');

      this.setProperties({
        customFilters: null,
        bookingType: null
      });

      bookingTypeShowController.setProperties({
        availableNow: false,
        spanMultipleDays: false,
        firstDay: null,
        lastDay: null,
        fromTime: '',
        toTime: '',
        duration: null,
        bookingId: '',
        bookingMode: 1,
        recurrenceErrors: [],
        editingPrimary: false,
        customFiltersSelected: [],
        showBookingTypeHelpMessageModal: false,
        bookingTypeAlert: null,
        bookingTypeInfo: null,
        bookingTypeAlertIsDismissed: false,
        bookingTypeInfoIsDismissed: false,
        bookingModeLastSearchIsRecurring: false,
        isAdminPrimaryResourceSearch: false
      });
    },

    _handleErrors: function _handleErrors(error) {

      // We really just want to check for the Max Ocurrences limit and ignore any other API exceptions
      if (error.responseJSON && error.responseJSON.ExceptionMessageKey === 'maxoccurrenceslimit') {
        var errorMessage = this.get('errorHandler').buildErrorMessage(error.responseJSON);

        if (errorMessage) {
          this.controllerFor('logged-in.booking-type.show').set('recurrenceErrorsServer', errorMessage.toString());
          return;
        }
      }
    },

    actions: {

      invalidateModel: function invalidateModel() {
        this.refresh();
      },

      // When transitioning away from this route
      willTransition: function willTransition(transition) {

        var controller = this.controllerFor('logged-in.booking-type.show');
        var resourceShowController = this.controllerFor('logged-in.resource.show');

        // If we're navigating to a route other than self or Make a Booking, clear the resource filters
        var names = ['logged-in.resource.show', 'logged-in.booking-type.show'];
        if (!names.includes(transition.targetName)) {
          this.get('customEvents').triggerClearResourceFilters();
        }

        /**
         * If target is "Make A Booking", pass the current bookingMode and recurrencePattern
         * as long as user is not cancelling Primary Resource mode
         */
        if (transition.targetName === 'logged-in.resource.show') {

          // We do not want to update destination page controller props if the user has cancelled the change of primary resource
          if (transition.queryParams.cancelPrimary) {
            return true;
          }

          // Update bookingMode and recurrencePattern on the target route
          resourceShowController.set('bookingMode', parseInt(controller.get('bookingMode')));
          resourceShowController.set('recurrencePattern', controller.get('recurrence'));

          // If the current bookingMode is Recurring and we have clicked Search Recurring, we make sure the
          // checkbox in the Make a Booking booking form is pre-selected
          if (controller.get('bookingMode') === 2 && controller.get('hasChangedReccurencePatternWhileEditing')) {
            resourceShowController.set('updateOccurrencePattern', true);
          }

          // In case editedPrimary and singleMode, do not show bookingForm when redirecting to calendar
          if (transition.queryParams.editedPrimary && controller.get('bookingMode') === 1) {
            resourceShowController.setProperties({
              fromTime: controller.get('fromTime'),
              toTime: controller.get('toTime')
            });

            if (!transition.queryParams.fromTime || !transition.queryParams.toTime) {
              resourceShowController.set('showBookingRequestForm', false);
            }
          }

          this._logResourceClick(transition);

          // Else if target is NOT self, unloadAll and reset MakeABooking controller properties as well
        } else if (transition.targetName !== this.routeName) {
            this.store.unloadAll();

            // Reset all properties for MakeABooking
            try {

              controller.setProperties({
                bookingId: '',
                editOrigin: null,
                editingPrimary: false
              });

              resourceShowController.resetProperties();
            } catch (error) {
              //noop
            }
          }

        return true;
      }

    },

    _logResourceClick: function _logResourceClick(transition) {
      var _this4 = this;

      // Don't log if value is 0
      if (parseInt(this.get('systemSettings').getSetting('resourceFilterAppInsightsLogLevel')) === 0) {
        return;
      }

      try {
        (function () {
          var resource = _this4.controller.get('model.resources').findBy('id', transition.intent.contexts[1]);
          var filters = _this4.controller.get('customFiltersSelected').map(function (filter) {
            return { name: filter.name, value: filter.value, type: filter.type };
          });

          var logData = {
            resourceName: resource.name,
            bookingTypeId: _this4.controller.get('model.bookingType.id'),
            bookingTypeName: _this4.controller.get('model.bookingType.name'),
            filters: JSON.stringify(filters)
          };

          var props = _this4.controller.getProperties(['query', 'availableNow', 'spanMultipleDays', 'firstDay', 'lastDay', 'fromTime', 'toTime', 'duration']);

          Object.keys(props).forEach(function (k) {
            logData[k] = _ember['default'].isEmpty(props[k]) ? null : props[k];
          });

          // let durations = ('All Day,15m,30m,1h,2h,3h').split(','); // we want to log in english no matter what the lang is selected
          // logData.duration = Ember.isEmpty(logData.duration) ? null : durations[parseInt(logData.duration)];

          _this4.get('appInsights').trackEvent('resource-found-filters', logData);
        })();
      } catch (e) {
        LE.error('Unable to log resource-found-filters', e);
      }
    }

  });
});