define('scientia-resourcebooker/services/booking-report-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),
    cachedReportData: null,
    appInsights: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    isAdmin: _ember['default'].computed(function () {
      return this.get('currentUser').isAdmin();
    }),

    fetch: function fetch(data, startRange, endRange, includeActivities) {
      var _this = this;

      this.get('appInsights').trackEvent('report-bookings-generate', {
        isAdmin: this.get('isAdmin'),
        fromDate: startRange.toISOString(),
        toDate: endRange.toISOString(),
        count: _ember['default'].get(data, 'length'),
        includeActivities: includeActivities
      });

      data = this._serializeData(data);
      var url = _scientiaResourcebookerConfigEnvironment['default'].API.RESOURCE_TREE_URLS.bookingRequests;

      url += '?StartRange=' + startRange.toISOString() + '&endRange=' + endRange.toISOString();
      if (includeActivities) url += '&includeResourceEvents=true';

      var columnsTranslations = this.getColumnTranslations();
      var payload = {
        columnHeaders: columnsTranslations,
        resourceControlNodes: data,
        redactedDataValue: this.get('i18n').t('components.reports.audit.column_names.redacted').toString()
      };
      return this.get('authenticatedRequest').post(url, payload, true).then(function (response) {
        return _this._normalizeData(response, includeActivities);
      });
    },

    fetchPrintDataByReportId: function fetchPrintDataByReportId(reportId) {
      var _this2 = this;

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.PRINT.BOOKING_REPORT.loc(reportId);
      return this.get('authenticatedRequest').fetch(url).then(function (response) {
        var data = _this2._normalizeData(response, true);
        return data.bookingRequests;
      });
    },

    setCachedReportData: function setCachedReportData(data) {
      this.set('cachedReportData', data);
    },

    fetchCachedReportData: function fetchCachedReportData() {
      return this.get('cachedReportData');
    },

    clearCachedReportData: function clearCachedReportData() {
      this.set('cachedReportData', null);
    },

    getColumnTranslations: function getColumnTranslations() {
      var _this3 = this;

      var columnNames = ['identity', 'reference', 'title', 'description', 'startTimeUtc', 'endTimeUtc', 'startTimeLocal', 'endTimeLocal', 'durationMinutes', 'bookerId', 'bookerName', 'bookerEmailAddress', 'resourceType', 'resourceName', 'additionalEmailAddresses', 'templateName', 'currentStatus', 'statusAfterAction', 'action', 'actionDateUtc', 'actionDateLocal', 'actionedBy', 'notes', 'form', 'imported', 'importedBy', 'source', 'executionStartedUtc', 'executionStartedLocal', 'executionEndedUtc', 'executionEndedLocal', 'isPrimaryResource', 'copiedFromReference', 'activityName', 'activityDescription', 'activityHostkey', 'activityType'];

      var translationPrefix = 'components.reports.audit.column_names.';
      var columnsTranslations = columnNames.reduce(function (memo, column) {
        var c = _this3.get('i18n').t(translationPrefix + column);
        memo[column] = c ? c.toString() : column;
        return memo;
      }, {});

      return columnsTranslations;
    },

    _serializeData: function _serializeData(data) {
      return data.map(function (d) {
        return {
          resourceTypeIdentity: d.resourceTypeId,
          resourceGroupIdentity: d.resourceGroupId,
          resourceIdentity: d.resourceId
        };
      });
    },

    _normalizeData: function _normalizeData(data, includeActivities) {

      // If no data return default object to prevent exceptions
      if (!data) {
        return {
          bookingRequests: [],
          events: []
        };
      }

      // Include events if toggle was on
      if (includeActivities && data.events.length) {
        data.events.forEach(function (ev) {
          ev.resources = (ev.resourceInfo || []).map(function (r) {
            return {
              resourceTypeName: r.resourceTypeName,
              name: r.resourceName,
              resourceTypeId: r.resourceTypeId,
              imageUri: r.resourceTypeImageUri
            };
          }).filter(function (r) {
            return r.resourceTypeId === _scientiaResourcebookerConfigEnvironment['default'].IDS.LOCATION_RESOURCE_TYPE;
          });

          ev.source = ev.source || 'tt-splus';

          data.bookingRequests.push(ev);
        });
      }

      // Sort if any data
      if (data.bookingRequests) {
        data.bookingRequests.sort(function (a, b) {
          if (a.startDateTime < b.startDateTime) {
            return a.endDateTime < b.endDateTime ? -1 : 1;
          }

          return a.startDateTime < b.startDateTime ? -1 : 1;
        });
      }

      return data;
    }
  });
});