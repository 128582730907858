define('scientia-resourcebooker/pods/components/reports/reports-bookings/component', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages) {
  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsFlashMessages['default'], {

    classNames: ['tabForm at-reports reportsBookings'],

    store: _ember['default'].inject.service(),
    resourceTreeDataFetcher: _ember['default'].inject.service(),
    bookingReportFetcher: _ember['default'].inject.service(),
    bookingReportExporter: _ember['default'].inject.service(),
    bookingRequestDestroyer: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),
    printHandler: _ember['default'].inject.service(),
    router: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    isLoading: false,

    // Holds root model (resource-types)
    model: null,

    // holds all selected objects
    selected: null,

    // current search query
    query: '',

    searchResults: null,

    // The current timeframe selected
    timeframeSelected: null,

    // The dates related to the time frame
    startDateTime: null,
    endDateTime: null,

    // Handles showing the resource picker or report reuslts
    isViewingReport: false,
    isFetchingReport: false,

    // The report bookings array
    bookingReportsModel: null,
    exportTokenCSV: null,

    reportId: null,

    editRestrictionsModal: null,
    showTimeframeModal: false,
    showBookingReportEmptyModal: false,

    showActivitiesToggleEnabled: false,
    showActivitiesToggleVisible: false,

    filterActiveBookings: false,

    // If the component has been loaded after transition from editing a booking back into a report
    // so we can go and fetch the previous report cached data and display the updated booking
    resumeReport: false,
    bookingId: '',
    occurrenceId: '',

    // Temporary timeframe modal properties
    _modalTimeframeSelected: null,
    _modalStartDateTime: null,
    _modalEndDateTime: null,

    hasResults: _ember['default'].computed('bookingReportsModel', function () {
      return !_ember['default'].isEmpty(this.get('bookingReportsModel'));
    }),

    isAdmin: _ember['default'].computed(function () {
      return this.get('currentUser').isAdmin();
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('selected', []);
      this.send('onTimeframeChange', 'next_seven_days');

      if (this.get('resumeReport')) {
        this._resumeCachedReport();
        this._showUpdatedBookingPopup();
      }

      this.fetchResourceTypes();
    },

    // List of available search types
    searchTypes: _ember['default'].computed('i18n.locale', function () {
      var _this = this;

      var labels = ['everything', 'resourcetypes', 'resourcegroups', 'resources'];
      return labels.map(function (label, i) {
        return {
          label: _this.get('i18n').t('components.resource_tree.search.' + label.toLowerCase()),
          value: i
        };
      });
    }),

    // list of available timeframe types
    timeframeTypes: _ember['default'].computed('i18n.locale', function () {
      var _this2 = this;

      var labels = ['today', 'next_seven_days', 'next_month', 'specific_range'];
      return labels.map(function (label) {
        return {
          label: _this2.get('i18n').t('components.date_period_selector.' + label),
          value: label
        };
      });
    }),

    searchTypePlaceholder: _ember['default'].computed('i18n.locale', function () {
      return this.get('i18n').t('components.resource_tree.search.search_type');
    }),

    fetchResourceTypes: function fetchResourceTypes() {
      var _this3 = this;

      this.set('isLoading', true);
      return this.get('resourceTreeDataFetcher').fetch({
        type: 'root.resourceType',
        data: {}
      }).then(function (data) {
        var result = _ember['default'].Object.create({
          name: 'resourcetypes',
          data: data.results,

          children: [_ember['default'].Object.create({
            name: 'resourcegroups',
            childDataParams: ['id'],
            loadChildData: function loadChildData(_ref, page) {
              var id = _ref.id;
              return _this3._fetchResourceGroups(id, page);
            }
          }), _ember['default'].Object.create({
            name: 'resources',
            childDataParams: ['id'],
            loadChildData: function loadChildData(_ref2, page) {
              var id = _ref2.id;
              return _this3._fetchResourcesForResourceType(id, page);
            }
          })]
        });

        _this3.set('model', result);
      })['finally'](function () {
        _this3.set('isLoading', false);
      });
    },

    /**
    * Fetches Groups for a given Resource Type
    * @param {string} resourceTypeId The resourceType Id
    */
    _fetchResourceGroups: function _fetchResourceGroups(resourceTypeId, page) {
      var _this4 = this;

      return this.get('resourceTreeDataFetcher').fetch({
        type: 'resourceType.resourceGroup',
        parentId: resourceTypeId,
        data: {
          pageNumber: page || 1,
          itemsPerPage: 10,
          getAssociatedTemplates: true,
          adminPermissionsOnly: true
        }
      }).then(function (data) {

        return _ember['default'].Object.create({
          name: 'resourcegroups',
          data: data.results,
          meta: data.meta,
          rank: 0,
          children: [_ember['default'].Object.create({
            name: 'resources',
            childDataParams: ['id'],
            loadChildData: function loadChildData(_ref3, page) {
              var resourceGroupId = _ref3.id;
              return _this4._fetchResourcesForGroup(resourceTypeId, resourceGroupId, page);
            }
          })]
        });
      });
    },

    /**
    * Fetches Resources for a given Resource Type
    * @param {string} resourceTypeId The resourceType Id
    */
    _fetchResourcesForResourceType: function _fetchResourcesForResourceType(resourceTypeId, page) {
      return this.get('resourceTreeDataFetcher').fetch({
        type: 'resourceType.resource',
        parentId: resourceTypeId,
        data: {
          pageNumber: page || 1, itemsPerPage: 10
        }
      }).then(function (data) {
        return _ember['default'].Object.create({
          name: 'resources',
          rank: 1,
          hideIcon: true,
          data: data.results,
          meta: data.meta
        });
      });
    },

    /**
    * Fetches resources for a given ResourceGroup
    * @param {string} resourceTypeId The resourceType Id
    * @param {string} groupId The group Id
    */
    _fetchResourcesForGroup: function _fetchResourcesForGroup(resourceTypeId, groupId, page) {
      return this.get('resourceTreeDataFetcher').fetch({
        type: 'resourceGroup.resource',
        parentId: resourceTypeId,
        id: null,
        groupId: groupId,
        suffix: null,
        data: {
          pageNumber: page || 1, itemsPerPage: 5, adminPermissionsOnly: true
        }
      }).then(function (data) {
        return _ember['default'].Object.create({
          name: 'resources',
          data: data.results,
          hideIcon: true,
          meta: data.meta
        });
      });
    },

    _shouldShowBookingRequestActions: function _shouldShowBookingRequestActions(status) {
      var shouldShow = ['Cancelled', 'Rejected'].indexOf(status) === -1;
      this.set('showBookingRequestActions', shouldShow);
    },

    _showUpdatedBookingPopup: function _showUpdatedBookingPopup() {
      var bookingId = this.get('bookingId');
      var occId = this.get('occurrenceId');

      this.send('openBookingRequestOverview', { occurrenceGroupId: occId, id: bookingId });

      this.get('onUpdatedBookingLoaded')();
    },

    /**
     * Resumes the previous report list data from cache.
     * This is triggered after the user has finished editing a booking through a booking report
     * Which allows him to be redirected to the same reported after successful edit
     */
    _resumeCachedReport: function _resumeCachedReport() {
      this.get('onCacheLoaded')();

      var cache = this.get('bookingReportFetcher').fetchCachedReportData();
      if (!cache || !cache.selected) {
        return;
      }

      this.setProperties(cache);
      this.get('bookingReportFetcher').clearCachedReportData();
      this.send('fetchBookingReport', cache.selected, cache.startDateTime, cache.endDateTime, cache.showActivitiesToggleEnabled);

      this.set('isViewingReport', true);
      this.get('session').stopAutoLogout();
    },

    _cacheReport: function _cacheReport() {
      var props = ['selected', 'searchResults', 'timeframeSelected', 'startDateTime', 'endDateTime', 'showActivitiesToggleVisible', 'showActivitiesToggleEnabled'];
      var data = this.getProperties(props);
      this.get('bookingReportFetcher').setCachedReportData(data);
    },

    _getAdjustedTimeframeDates: function _getAdjustedTimeframeDates(start, end) {
      if (!start) {
        if (end) start = end.clone().startOf('day');
      }

      if (!end) {
        end = start.clone().endOf('day');
      }

      if (end && start && end.isBefore(start)) {
        end = start.clone().endOf('day');
      }

      return { startDate: start, endDate: end };
    },

    actions: {

      //  When clicking on an item
      onSelect: function onSelect() {
        var args = arguments;
        var item = args[args.length - 1];
        var itemType = args[args.length - 2];
        if (!itemType && args.length === 1) {
          itemType = 'resourcetypes';
        }

        var selected = this.get('selected');

        var existingItem = selected.findBy('id', item.id);
        if (_ember['default'].isEmpty(existingItem)) {
          var itemId = _ember['default'].get(item, 'id');
          var typeRank = ['resourcetypes', 'resourcegroups', 'resources'];
          var itemData = {
            id: itemId,
            resourceTypeId: _ember['default'].get(args[0], 'id'),
            resourceGroupId: itemType === 'resourcegroups' ? itemId : null,
            resourceId: itemType === 'resources' ? itemId : null,
            name: _ember['default'].get(item, 'name'),
            imageUri: _ember['default'].get(item, 'imageUri'),
            type: itemType,
            rank: typeRank.indexOf(itemType)
          };

          selected.pushObject(itemData);
        } else {
          selected.removeObject(existingItem);
        }
      },

      onSelectSearchResultItem: function onSelectSearchResultItem(itemType, item) {

        var selected = this.get('selected');
        var existingItem = selected.findBy('id', item.id);
        if (_ember['default'].isEmpty(existingItem)) {
          var itemId = _ember['default'].get(item, 'id');
          var typeRank = ['resourcetypes', 'resourcegroups', 'resources'];
          var itemData = {
            id: itemId,
            resourceTypeId: itemType === 'resourcetypes' ? itemId : null,
            resourceGroupId: itemType === 'resourcegroups' ? itemId : null,
            resourceId: itemType === 'resources' ? itemId : null,
            name: _ember['default'].get(item, 'name'),
            type: itemType,
            rank: typeRank.indexOf(itemType)
          };
          selected.pushObject(itemData);
        } else {
          selected.removeObject(existingItem);
        }
      },

      // When removing an item from selection
      onRemoveItem: function onRemoveItem(item) {
        this.get('selected').removeObject(item);
      },

      // #region Modal Timeframe actions

      // When opening timeframe modal set initial modal properties
      onShowTimeframeModal: function onShowTimeframeModal() {
        var timeframeSelected = this.get('timeframeSelected');
        this.setProperties({
          _modalTimeframeSelected: timeframeSelected,
          _modalStartDateTime: timeframeSelected === 'specific_range' ? this.get('startDateTime') : moment().startOf('day'),
          _modalEndDateTime: timeframeSelected === 'specific_range' ? this.get('endDateTime') : moment().endOf('day'),
          showTimeframeModal: true
        });
      },

      // Update dates for timeframe modal
      onTimeframeModalDatesChange: function onTimeframeModalDatesChange(start, end) {
        var _getAdjustedTimeframeDates2 = this._getAdjustedTimeframeDates(start, end);

        var startDate = _getAdjustedTimeframeDates2.startDate;
        var endDate = _getAdjustedTimeframeDates2.endDate;

        this.setProperties({
          _modalStartDateTime: startDate,
          _modalEndDateTime: endDate
        });
      },

      // Update timeframe for timeframe modal
      onTimeframeModalTimeframeChange: function onTimeframeModalTimeframeChange(timeframe) {
        this.set('_modalTimeframeSelected', timeframe);
      },

      // When cancelling modal just close it and reset properties to null
      onTimeframeModalCancel: function onTimeframeModalCancel() {
        this.setProperties({
          showTimeframeModal: false,
          _modalTimeframeSelected: null,
          _modalStartDateTime: null,
          _modalEndDateTime: null
        });
      },

      onTimeframeModalConfirm: function onTimeframeModalConfirm() {
        // set new timeframe,start and end date
        // reload reports

        this.set('showTimeframeModal', false);

        var modalTimeframeSelected = this.get('_modalTimeframeSelected');
        this.send('onTimeframeChange', modalTimeframeSelected);

        if (modalTimeframeSelected === 'specific_range') {
          this.send('onTimeframeDatesChange', this.get('_modalStartDateTime'), this.get('_modalEndDateTime'));
        }

        this.send('onRefreshReport');
      },

      // #region timeframe modal
      /*****************************
      * /END TIMEFRAME MODAL ACTIONS
      ******************************/

      onTimeframeChange: function onTimeframeChange(rangeType) {
        this.set('timeframeSelected', rangeType);
        if (rangeType === 'specific_range') {
          this.setProperties({
            startDateTime: moment().startOf('day'),
            endDateTime: moment().add(6, 'days').endOf('day')
          });
          return;
        } else {

          var start = undefined;
          var end = undefined;
          switch (rangeType) {

            case 'today':
              start = moment().startOf('day');
              end = moment().endOf('day');
              break;

            case 'next_seven_days':
              start = moment().startOf('day');
              end = start.clone().add(6, 'days').endOf('day');
              break;

            case 'next_month':
              start = moment().startOf('day');
              end = start.clone().add(1, 'month').startOf('day');
              break;

            default:
              break;
          }
          this.setProperties({
            startDateTime: start,
            endDateTime: end
          });
        }
      },

      onTimeframeDatesChange: function onTimeframeDatesChange(start, end) {
        var _getAdjustedTimeframeDates3 = this._getAdjustedTimeframeDates(start, end);

        var startDate = _getAdjustedTimeframeDates3.startDate;
        var endDate = _getAdjustedTimeframeDates3.endDate;

        this.setProperties({
          startDateTime: startDate,
          endDateTime: endDate
        });
      },

      // When submiting a search
      onSearch: function onSearch(query, type) {
        var _this5 = this;

        this.setProperties({
          query: query,
          isLoading: true
        });

        this.get('resourceTreeDataFetcher').search(query, type || 0).then(function (data) {
          _this5.set('searchResults', data);
        })['finally'](function () {
          _this5.set('isLoading', false);
        });
      },

      onClearSearch: function onClearSearch() {
        this.setProperties({
          query: '',
          searchResults: null
        });
      },

      clearSelection: function clearSelection() {
        this.set('selected', []);
      },

      fetchBookingReport: function fetchBookingReport(selected, startRange, endRange, includeActivities) {
        var _this6 = this;

        this.set('isFetchingReport', true);
        this.get('bookingReportFetcher').fetch(selected, startRange, endRange, includeActivities).then(function (data) {

          _this6.set('bookingReportsModel', data.bookingRequests);
          _this6.set('exportTokenCSV', data.csvFileAccessCode);
          _this6.set('reportId', data.bookingRequestsKey);

          // todo: to update when endpoint returns a boolean indicating the toggle can be displayed
          _this6.set('showActivitiesToggleVisible', data.hasExternallyManagedResources);

          _this6.set('isViewingReport', true);
        })['finally'](function () {
          _this6.set('isFetchingReport', false);
        });

        // Needs to be on a setTimeout so that we give change for the async call above to initiate
        _ember['default'].run.later(this, function () {
          _this6.get('session').stopAutoLogout();
        }, 300);
      },

      onRefreshReport: function onRefreshReport() {
        this.get('session').restoreAutoLogout();
        this.get('appInsights').trackEvent('report-bookings-refresh', { isAdmin: this.get('isAdmin') });

        _ember['default'].run.later(this, 'send', 'fetchBookingReport', this.get('selected'), this.get('startDateTime'), this.get('endDateTime'), this.get('showActivitiesToggleEnabled'), 100);
      },

      onExportCSV: function onExportCSV() {
        if (!this.get('exportTokenCSV')) return;
        this.get('appInsights').trackEvent('report-bookings-export', { isAdmin: this.get('isAdmin') });
        this.get('bookingReportExporter')['export'](this.get('exportTokenCSV'));
      },

      onPrintClick: function onPrintClick() {
        this.get('session').stopAutoLogout();
        var startRange = moment(this.get('startDateTime'));
        var endRange = moment(this.get('endDateTime'));

        var model = { startRange: startRange, endRange: endRange, from: 'reports' };
        model.id = this.get('reportId');
        model.timeframe = this.get('timeframeSelected');
        if (this.get('filterActiveBookings')) model.filterActiveBookings = true;

        this.get('appInsights').trackEvent('report-bookings-print');
        this.get('printHandler').handle({ printType: 'report', model: model });
      },

      onFilterActiveBookings: function onFilterActiveBookings() {
        var value = this.get('filterActiveBookings');
        this.get('appInsights').trackEvent('report-bookings-toggle-active-bookings', { value: !value });

        this.toggleProperty('filterActiveBookings');
      },

      onToggleShowActivities: function onToggleShowActivities() {
        this.toggleProperty('showActivitiesToggleEnabled');
        this.send('onRefreshReport');
      },

      openBookingRequestOverview: function openBookingRequestOverview(chronosEvent) {
        var _this7 = this;

        this.get('session').restoreAutoLogout();
        this.set('loadingBookingRequest', chronosEvent.id);

        var bookingPromise = undefined;
        if (chronosEvent.occurrenceGroupId) {
          bookingPromise = this.get('store').queryRecord('booking-request', { bookingId: chronosEvent.occurrenceGroupId, occurrenceId: chronosEvent.id });
        } else {
          bookingPromise = this.get('store').findRecord('booking-request', chronosEvent.id);
        }

        bookingPromise.then(function (bookingRequest) {
          _this7.set('showBookingRequest', bookingRequest);
          _this7._shouldShowBookingRequestActions(bookingRequest.get('status'));
        })['finally'](function () {
          _this7.set('loadingBookingRequest', false);
        });
      },

      closeBookingRequest: function closeBookingRequest() {
        this.set('showBookingRequest', false);
        this.get('session').stopAutoLogout();
      },

      editBookingRequest: function editBookingRequest(bookingRequest) {
        var singleOccurrenceEdit = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

        var _this8 = this;

        var editRestrictions = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];
        var isCopyMode = arguments.length <= 3 || arguments[3] === undefined ? false : arguments[3];

        this.get('session').restoreAutoLogout();
        var bookingId = bookingRequest.get('eventId');
        var occurrenceId = singleOccurrenceEdit ? bookingRequest.get('id') : null;

        var queryParams = {
          bookingId: bookingId,
          date: moment(bookingRequest.get('startDateTime')).format('YYYY-MM-DD'),
          editFromPage: 'report',
          isCopyMode: isCopyMode
        };

        if (occurrenceId) queryParams.occurrenceId = occurrenceId;

        // If there are no restrictions just proceed to edit
        if (_ember['default'].isEmpty(editRestrictions)) {
          this.send('editBookingRequestConfirm', bookingRequest, queryParams);
          return;
        }

        // Else create a deferred promise which will be resolved or rejected depending if the user chooses to continue with the edit or cancel
        var deferred = _ember['default'].RSVP.defer();

        //If user confirms then proceed to edit
        deferred.promise.then(function () {
          _this8.send('editBookingRequestConfirm', bookingRequest, queryParams);
        })['catch'](function () {
          _this8.set('editRestrictionsModal', null);
        });

        // Trigger the modal and pass in the deferred and restrictions
        this.set('editRestrictionsModal', {
          deferred: deferred,
          restrictions: editRestrictions,
          isOccurrence: singleOccurrenceEdit,
          isCopyMode: isCopyMode
        });
      },

      editBookingRequestConfirm: function editBookingRequestConfirm(bookingRequest, queryParams) {

        // Let's cache our component data before redirecting to edit booking so we can retrieve it when we return
        this._cacheReport();
        this.get('router').transitionTo('logged-in.resource.show', bookingRequest.get('bookingTypeId'), bookingRequest.get('resources.firstObject.id'), { queryParams: queryParams });
      },

      copyBookingRequest: function copyBookingRequest(bookingRequest) {
        var singleOccurrenceEdit = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];
        var editRestrictions = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

        this.send('editBookingRequest', bookingRequest, singleOccurrenceEdit, editRestrictions, true);
      },

      cancelBookingRequest: function cancelBookingRequest(bookingRequest, cancelType) {
        var numberOfOcurrences = arguments.length <= 2 || arguments[2] === undefined ? 1 : arguments[2];

        this.get('session').restoreAutoLogout();
        var occurrencesCount = bookingRequest.get('hasRecurrence') ? numberOfOcurrences : null;
        this.set('bookingRequestScheduledForCancellation', { booking: bookingRequest, cancelType: cancelType, occurrencesCount: occurrencesCount });
      },

      confirmBookingRequestCancellation: function confirmBookingRequestCancellation(bookingRequest, cancelType, note) {
        var _this9 = this;

        this.get('bookingRequestDestroyer').destroyRequest(bookingRequest, cancelType, note).then(function () {
          _this9.deleteSuccessFlash('booking-request');

          var currentUserEmail = _this9.get('currentUser.user.email');
          var bookerEmail = bookingRequest.get('bookerEmailAddress');
          _this9.get('appInsights').trackEvent('booking-cancelled', {
            type: cancelType === 1 ? 'occurrence' : 'booking',
            isOwner: currentUserEmail === bookerEmail,
            bookingId: bookingRequest.get('id'),
            bookingName: bookingRequest.get('name'),
            from: 'reports'
          });

          _this9.send('onRefreshReport');

          // todo: remove delete booking from current list?
        })['catch'](function (error) {
          if (error.responseJSON) {
            var errorMessage = _this9.get('bookingRequestErrorHandler').handle(error, 'pods.logged_in.booking_request.index.flash_messages');
            errorMessage = errorMessage.replace(new RegExp('\<\/br\>', 'g'), ', ');
            if (errorMessage) {
              _this9.flashError(errorMessage);
              return;
            }
          }

          _this9.deleteErrorFlash('booking-request');
        })['finally'](function () {
          _this9.setProperties({
            showBookingRequest: null,
            bookingRequestScheduledForCancellation: null
          });
        });
      },

      returnToReportPicker: function returnToReportPicker() {
        this.get('session').restoreAutoLogout();
        this.set('isViewingReport', false);
        this.set('showActivitiesToggleEnabled', false);
      }
    }

  });
});