define('scientia-resourcebooker/pods/components/booking-request-overview/timedate-info/component', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['bookingRequestOverview-timeDate-wrapper'],
    attributeBindings: ['role', 'tabindex', 'aria-label'],
    i18n: _ember['default'].inject.service(),

    role: 'region',
    tabindex: '0',
    'aria-label': (0, _emberI18n.translationMacro)('components.booking_request_overview.booking_date_time_label'),

    bookingDateFormat: (0, _emberI18n.translationMacro)('components.booking_request_overview.date_format'),

    bookingDateTimeFormat: (0, _emberI18n.translationMacro)('components.booking_request_overview.date_format_full'),

    bookingTimeFormat: (0, _emberI18n.translationMacro)('components.booking_request_overview.time_format'),

    spansMultipleDays: _ember['default'].computed('model.startDateTime', 'model.endDateTime', function () {
      if (!this.get('model')) return false;
      var start = this.get('model.startDateTime');
      var end = this.get('model.endDateTime');

      if (!start || !end) return false;

      // Subtract 1min from endTime as 00:00 is considered next day and would fail our boolean below
      return !moment(start).isSame(moment(end).clone().subtract(1, 'minute'), 'day');
    })

  });
});