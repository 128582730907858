define('scientia-resourcebooker/pods/components/rb-calendar/component', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var get = _ember['default'].get;

  var DEFAULT_GUID = '0000-0000-0000-0000';
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':rb-calendar', 'location', 'compactMode', 'contrastMode'],
    systemSettings: _ember['default'].inject.service(),
    recurrenceProcessor: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    currentLanguage: _ember['default'].computed.alias('i18n.locale'),
    localeProcessor: _ember['default'].inject.service(),
    calendar: null,
    calendarEvents: [],
    unavailableLabel: (0, _emberI18n.translationMacro)('components.rb_calendar.unavailable'),

    calendarStartTime: null,
    calendarEndTime: null,
    useCustomCalendarOptions: null,
    showWeekends: null,
    addAriaLabelsToHourSlots: false,

    categories: null,
    lanesToggled: false,
    lanesWidthPerHour: 200,

    compactMode: false,
    contrastMode: false,
    zoomLevel: 0,

    'event-id-path': 'id',
    'event-title-path': 'title',
    'event-subtitle-path': 'subtitle',
    'event-from-time-path': 'fromTime',
    'event-to-time-path': 'toTime',
    'event-tag-path': 'tag',
    'event-extra-class-names-path': 'extraClassNames',
    'event-parent-id-path': 'parentId',

    'displayed-date': null,
    'default-view-type': _ember['default'].computed.alias('systemSettings.options.calendarDefaultViewType'),
    'small-view-type': _ember['default'].computed.oneWay('systemSettings.options.calendarSmallViewType'),
    'show-agenda-button': true,
    'show-day-button': true,
    'show-week-button': true,
    'show-month-button': true,
    'show-year-button': false,
    'show-previous-arrow': true,
    'show-next-arrow': true,
    'show-today-button': true,
    'show-toolbar': true,
    'show-tag-button': false,
    'show-datepicker-button': true,
    'weekend-days': '',
    'show-lanes-toggler-button': false,

    'first-day-of-the-week-offset': _ember['default'].computed.alias('systemSettings.options.calendarFirstDayOfTheWeekOffset'),
    currentViewType: _ember['default'].computed.oneWay('default-view-type'),

    debounceDelay: _ember['default'].computed.alias('systemSettings.options.calendarNavigationLoadDelay'),

    slotRangeDragEnabled: false,
    institutionSettingDragDisabled: _ember['default'].computed.alias('systemSettings.options.calendarDragDisabled'),
    updateCalendar: (function () {
      _ember['default'].run.once(this, '_replaceEvents');
      _ember['default'].run.later(this, '_initializeDragEvents', 200);
    }).observes('calendarEvents', 'calendarEvents.[]'),

    updateDisplayedDate: (function () {
      if (this.get('displayed-date')) {

        var calendarDate = this.get('calendar.displayedDate');
        var displayedDate = this.get('displayed-date');

        if (calendarDate && displayedDate && calendarDate.isSame(displayedDate, 'day')) return;

        this.get('calendar').goToDate(this.get('displayed-date'));
      }
    }).observes('displayed-date'),

    updateZoomLevel: (function () {
      _ember['default'].run.next(this, function () {
        this.get('calendar').setZoomLevel(this.get('zoomLevel'));
      });
    }).observes('zoomLevel'),

    onInit: (function () {
      _ember['default'].run.schedule('afterRender', this, 'setup');
    }).on('init'),

    setup: function setup() {
      var _this = this;

      var displayedDate = this.get('displayed-date');

      var translationObject = this.get('localeProcessor').getChronosTranslations();

      var categories = this._getCategories();
      this.set('dataSource', new Chronos.DataSource([], categories));

      var useCustomHours = this.get('useCustomCalendarOptions');
      var startHour = this._getStartHourFromTime(this.get('calendarStartTime'));
      var endHour = this._getEndHourFromTime(this.get('calendarEndTime'));
      var showWeekends = this.get('showWeekends');

      var systemStartTime = this._getStartHourFromTime(this.get('systemSettings').getSetting('calendarStartTime'));
      var systemEndTime = this._getEndHourFromTime(this.get('systemSettings').getSetting('calendarEndTime'));
      var systemShowWeekends = this.get('systemSettings').getSetting('calendarShowWeekends');

      var currentCalendarData = {
        startHour: useCustomHours && startHour !== null ? startHour : systemStartTime,
        endHour: useCustomHours && endHour !== null ? endHour : systemEndTime,
        showWeekends: useCustomHours && showWeekends !== null ? showWeekends : systemShowWeekends
      };

      this.set('calendar', new Chronos.Calendar(this.$(), {
        translations: translationObject,
        configuration: {
          displayedDate: moment(displayedDate).isValid() ? displayedDate : null,
          defaultViewType: this.get('default-view-type') || this.get('systemSettings').getSetting('calendarDefaultViewType'),
          lanesToggled: this.get('lanesToggled'),
          lanesWidthPerHour: this.get('lanesWidthPerHour'),
          zoomLevel: this.get('zoomLevel') || 0,
          addAriaLabelsToHourSlots: this.get('addAriaLabelsToHourSlots'),
          toolbar: {
            showToolbar: this.get('show-toolbar'),
            showAgendaButton: this.get('show-agenda-button'),
            showDayButton: this.get('show-day-button'),
            showWeekButton: this.get('show-week-button'),
            showMonthButton: this.get('show-month-button'),
            showYearButton: this.get('show-year-button'),
            showPreviousArrow: this.get('show-previous-arrow'),
            showNextArrow: this.get('show-next-arrow'),
            showTodayButton: this.get('show-today-button'),
            showTagButton: this.get('show-tag-button'),
            showZoomButton: true,
            showDatepickerButton: this.get('show-datepicker-button'),
            showLanesTogglerButton: this.get('show-lanes-toggler-button')
          },

          defaultSlotStartOffsetInHours: currentCalendarData.startHour,
          defaultSlotEndOffsetInHours: currentCalendarData.endHour === 0 ? 0 : 24 - currentCalendarData.endHour,
          showWeekends: currentCalendarData.showWeekends,

          weekendDays: this.get('systemSettings').getSetting('weekendDays'),
          maximumConcurrentEvents: 5,
          firstDayOfTheWeekOffset: this.get('first-day-of-the-week-offset'),
          agendaShowDuration: this.get('systemSettings').getSetting('calendarAgendaViewShowDuration'),
          smallViewType: this.get('small-view-type') || 'month'
        },
        dataSource: this.get('dataSource'),
        onEventClick: function onEventClick(chronosEvent) {
          return _this.sendAction('on-event-click', chronosEvent);
        },
        onDateClick: function onDateClick(date) {
          return _this._onDateClick(date);
        },
        onDayHeaderClick: function onDayHeaderClick(date) {
          return _this._onDayHeaderClick(date);
        },
        onDatepickerClick: function onDatepickerClick(date) {
          return _this._onDatepickerClick(date);
        },
        onSheetChange: function onSheetChange(startDate, endDate) {
          return _this.onSheetChange(startDate, endDate);
        },
        onLanesToggled: function onLanesToggled(v) {
          return _this.onLanesToggled(v);
        },
        onTodayClick: function onTodayClick() {
          return _this.onTodayClick();
        },
        onZoomChanged: function onZoomChanged(zoomLevel) {
          return _this._onZoomChanged(zoomLevel);
        },

        onViewTypeChanged: function onViewTypeChanged(viewType) {
          if (!_this.get('isDestroyed')) {
            _this.sendAction('on-view-type-changed', viewType);
            _this.set('currentViewType', viewType);
            _this._datepickerUpdateViewMode(viewType);
            _ember['default'].run.later(_this, '_initializeDragEvents', 2000);

            // Update aria region with label
            try {
              var contentRegion = _this.$('.chronos-monthView#chronos-content-anchor');
              if (contentRegion) {
                contentRegion.attr('role', null);
                contentRegion.attr('tabindex', '0');
              }
            } catch (e) {
              // no-op
            }
          }
        }
      }));

      _ember['default'].run.later(this, function () {
        this._replaceEvents();
        if (this.get('show-datepicker-button')) {
          this._initializeDatepicker();
        }
        this.sendAction('on-calendar-loaded');
      });

      if (this.get('slotRangeDragEnabled')) {
        _ember['default'].run.later(this, '_initializeDragEvents', 2000);
      }
    },

    onSheetChange: function onSheetChange(startDate, endDate) {
      _ember['default'].run.debounce(this, '_onSheetChange', startDate, endDate, this.get('debounceDelay') || 1000);
    },

    _onSheetChange: function _onSheetChange(startDate, endDate) {
      var calendar = this.get('calendar');
      if (calendar && calendar.$element) {
        this.sendAction('on-displayed-date-change', calendar.displayedDate);
        this.sendAction('on-sheet-change', startDate, endDate);
        this._datepickerUpdateDate(calendar.displayedDate);
        _ember['default'].run.later(this, '_initializeDragEvents', 200);
      }
    },

    onLanesToggled: function onLanesToggled(value) {
      this.sendAction('on-lanes-toggled', value);
    },

    onTodayClick: function onTodayClick() {
      // deprecated by using show/hide previous days
    },

    _onZoomChanged: function _onZoomChanged(zoomLevel) {
      this.sendAction('onZoomChanged', zoomLevel);
      _ember['default'].run.later(this, '_initializeDragEvents', 500);
    },

    willDestroyElement: function willDestroyElement() {
      if (!this.get('isDestroyed') && this.get('calendar') && this.get('calendar.destroy')) {
        this.$('.chronos-headerView-title').off('keydown click');
        this.get('calendar').destroy();
      }
    },

    _replaceEvents: function _replaceEvents() {
      this.get('dataSource').replaceEvents(this._mappedEvents(), this._getCategories());
    },

    _mappedEvents: function _mappedEvents() {
      var _this2 = this;

      if (!this.get('calendarEvents') || !this.get('calendarEvents.length')) return [];
      return this.get('calendarEvents').map(function (chronosEvent) {

        if (get(chronosEvent, _this2.get('event-from-time-path')) === undefined) return false;

        return new Chronos.Event({
          id: get(chronosEvent, _this2.get('event-id-path')),
          title: _this2._getEventTitle(chronosEvent),
          fromTime: get(chronosEvent, _this2.get('event-from-time-path')),
          toTime: get(chronosEvent, _this2.get('event-to-time-path')),
          tag: get(chronosEvent, _this2.get('event-tag-path')) ? get(chronosEvent, _this2.get('event-tag-path')).toLowerCase() : '',
          hasRecurrence: get(chronosEvent, 'hasRecurrence'),
          extraClassNames: get(chronosEvent, _this2.get('event-extra-class-names-path')) || null,
          isMasking: get(chronosEvent, 'isMasking'),
          parentId: get(chronosEvent, _this2.get('event-parent-id-path')) || DEFAULT_GUID,
          recurrenceText: !!get(chronosEvent, 'recurrencePattern') && !!get(chronosEvent, 'recurrencePattern.recurrenceTypeSelected') ? _this2.get('recurrenceProcessor').generateDescription(get(chronosEvent, 'recurrencePattern')) : ''
        });
      });
    },

    _getCategories: function _getCategories() {
      var categories = this.get('categories');
      var defaultCategory = { id: DEFAULT_GUID, name: this.get('i18n').t('calendar.common.uncategorized') };
      return categories && categories.length ? categories : [defaultCategory];
    },

    _getStartHourFromTime: function _getStartHourFromTime(time) {
      if (!time) return null;

      var _time$split = time.split(':');

      var _time$split2 = _slicedToArray(_time$split, 1);

      var h = _time$split2[0];

      return parseInt(h, 10);
    },

    _getEndHourFromTime: function _getEndHourFromTime(time) {
      if (!time) return null;

      var _time$split3 = time.split(':');

      var _time$split32 = _slicedToArray(_time$split3, 2);

      var h = _time$split32[0];
      var m = _time$split32[1];

      h = parseInt(h, 10);
      m = parseInt(m, 10);
      if (m !== 0) h++;
      return h;
    },

    _initializeDatepicker: function _initializeDatepicker() {
      var _this3 = this;

      var input = _ember['default'].$('.chronos-headerView-datepicker-input').attr('aria-label', 'Datepicker').attr('aria-hidden', true);
      if (this.get('isDestroyed') || !input) return;

      input.attr('readonly', 'readonly');

      // Are we using custom calendar options? (ie Template on MAB overriding system calendar settings)
      var useCustomCalendarOptions = this.get('useCustomCalendarOptions');
      var showWeeekends = useCustomCalendarOptions ? this.get('showWeekends') : this.get('systemSettings').getSetting('calendarShowWeekends');
      var disabledDays = showWeeekends ? [] : this.get('systemSettings').getSetting('weekendDays') || [];

      var datepicker = input.datetimepicker({
        locale: this.get('currentLanguage') === 'en' ? 'en_GB' : this.get('currentLanguage'),
        format: 'YYYY-MM-DD',
        date: this.get('displayed-date'),
        ignoreReadonly: true,
        debug: false,
        daysOfWeekDisabled: disabledDays,
        tooltips: this._getDatepickerTranslations(),
        firstDayOfTheWeekOffset: this.get('systemSettings').getSetting('calendarFirstDayOfTheWeekOffset'),
        widgetPositioning: { vertical: 'bottom', horizontal: 'auto' }
      });

      datepicker.on('dp.change', function (e) {
        if (_this3.get('calendar') && _this3.get('calendar').$element) _this3.get('calendar').goToDate(e.date);
      });

      this.set('datepicker', datepicker.data('DateTimePicker'));

      this._datepickerUpdateViewMode(this.get('currentViewType'));

      this.$('.chronos-headerView-title').attr('role', 'button');

      // Datepicker toggle event handlers
      this.$('.chronos-headerView-title').on('keydown', function (e) {
        if (e.key === 'Enter') _this3._onDatepickerClick();
      });

      this.$('.chronos-headerView-title').on('click', function () {
        _this3._onDatepickerClick();
      });
    },

    _initializeDragEvents: function _initializeDragEvents() {
      var _this4 = this;

      if (!this.get('slotRangeDragEnabled') || this.get('institutionSettingDragDisabled')) return;

      // To prevent adding duplicate eventListeners we are checking if the elements already contained
      // the draggable element which is added when the event listeners are added. (We only need to check 1)
      var hourGridItems = document.querySelector('.chronos-weekView-hourGrid-item');
      if (hourGridItems && hourGridItems.getAttribute('draggable')) {
        return;
      }

      var dragImage = new Image();
      dragImage.src = 'assets/transparent.gif';
      var startSlot = null;
      var endSlot = null;
      var dayRow = null;

      var clearHighlightSlots = function clearHighlightSlots() {
        _ember['default'].$('.chronos-weekView-hourGrid-item').removeClass('chronos-weekView-hourGrid-item--dragging chronos-weekView-hourGrid-item--error');
      };

      var highLightSlots = function highLightSlots(isRangeInvalid) {
        var start = startSlot.getAttribute('data-hour');
        var end = endSlot ? endSlot.getAttribute('data-hour') : start;
        if (!start || !end || !dayRow) return;

        var tmp = parseInt(start, 10);
        start = parseInt(start, 10);
        end = parseInt(end, 10);
        if (end < start) {
          start = end;
          end = tmp;
        }

        clearHighlightSlots();
        for (var i = start; i <= end; i++) {
          dayRow.querySelector('.chronos-weekView-hourGrid-item[data-hour="' + i).classList.add(isRangeInvalid ? 'chronos-weekView-hourGrid-item--error' : 'chronos-weekView-hourGrid-item--dragging');
        }

        // Lets clear red highlighted cells after 300ms
        if (isRangeInvalid) {
          _ember['default'].run.later(_this4, function () {
            clearHighlightSlots();
          }, 300);
        }
      };

      _ember['default'].$('.chronos-weekView-hourGrid-item').attr('draggable', true);

      Array.from(document.querySelectorAll('.chronos-weekView-hourGrid-item')).forEach(function (el) {

        // drag
        el.addEventListener('drag', function () {}, false);

        // dragstart
        el.addEventListener('dragstart', function (e) {
          startSlot = e.target;
          dayRow = _ember['default'].$(startSlot).parents('.chronos-weekView-day')[0];
          endSlot = null;

          e.dataTransfer.setDragImage(dragImage, 0, 0);
          e.dataTransfer.effectAllowed = 'move';

          _ember['default'].$('.chronos-weekView-hourGrid-item').removeClass('chronos-weekView-hourGrid-item--dragging');
          highLightSlots();
        }, false);

        // dragend
        el.addEventListener('dragend', function () {
          clearHighlightSlots();
          var startRange = moment(dayRow.getAttribute('data-date')).startOf('hour').hour(startSlot.getAttribute('data-hour'));
          var endRange = startRange.clone().hour(endSlot.getAttribute('data-hour'));
          var tmp = endRange.clone();
          if (endRange.isBefore(startRange)) {
            endRange = startRange;
            startRange = tmp;
          }

          // Emit action and check if the response is invalid. If it is we highlight the slots with red
          if (_this4.get('on-date-range-drag')) {
            var isValidRange = _this4.get('on-date-range-drag')(startRange, endRange);
            if (!isValidRange) {
              highLightSlots(true);
            }
          }
        }, false);

        // dragover
        el.addEventListener('dragover', function (e) {
          // prevent default to allow drop
          e.preventDefault();
        }, false);

        el.addEventListener('dragenter', function (e) {
          e.dataTransfer.dropEffect = 'move';
          endSlot = e.target;
          highLightSlots();
        }, false);

        el.addEventListener('dragleave', function () {}, false);

        el.addEventListener('drop', function (e) {
          e.preventDefault();
        }, false);
      });
    },

    _onDatepickerClick: function _onDatepickerClick() {
      if (!this.get('datepicker')) return;
      _ember['default'].run.later(this, function () {
        this.get('datepicker').toggle();
      }, 100);
    },

    _getDatepickerTranslations: function _getDatepickerTranslations() {
      var _this5 = this;

      var defaults = _ember['default'].$.fn.datetimepicker.defaults.tooltips;
      var tooltips = {};

      Object.keys(defaults).forEach(function (key) {
        tooltips[key] = _this5.get('i18n').t('components.datetimepicker.' + key);
      });

      return tooltips;
    },

    _datepickerUpdateViewMode: function _datepickerUpdateViewMode(viewType) {
      if (!this.get('datepicker')) return;
      var mode = ['month', 'agenda'].includes(viewType) ? 'months' : 'days';
      this.get('datepicker').viewMode(mode);
    },

    _datepickerUpdateDate: function _datepickerUpdateDate(date) {
      if (!this.get('datepicker')) return;
      this.get('datepicker').date(date);
    },

    _getEventTitle: function _getEventTitle(event) {
      var title = get(event, this.get('event-title-path'));
      var subtitlePath = this.get('event-subtitle-path');

      // If Exam, use Exam translation string
      if (event.availabilityType && event.availabilityType === 7) {
        return this.get('i18n').t('calendar.header.tags.exam').toString();
      }

      if (title) {
        if (subtitlePath) {
          var subtitle = get(event, subtitlePath);
          if (subtitle) return title + (' (' + subtitle + ')');else return title;
        } else {
          return title;
        }
      }
      return this.get('unavailableLabel').toString();
    },

    _onDateClick: function _onDateClick(date) {
      if (this.get('calendar').viewType === 'month') {
        this.sendAction('on-event-count-badge-click', date);
      } else {
        this.sendAction('on-date-click', date);
      }
    },

    _onDayHeaderClick: function _onDayHeaderClick(date) {
      this.sendAction('on-day-header-click', date);
    }
  });
});