define('scientia-resourcebooker/services/booking-request-creator', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    nestedResourceStore: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),

    createRequest: function createRequest(_ref) {
      var resources = _ref.resources;
      var startDateTime = _ref.startDateTime;
      var endDateTime = _ref.endDateTime;
      var description = _ref.description;
      var name = _ref.name;
      var additionalEmailAddresses = _ref.additionalEmailAddresses;
      var recurrencePattern = _ref.recurrencePattern;
      var bookingTypeId = _ref.bookingTypeId;
      var owner = _ref.owner;
      var bookerEmailAddress = _ref.bookerEmailAddress;
      var bookerName = _ref.bookerName;
      var bookingSource = _ref.bookingSource;
      var copiedFromIdentity = _ref.copiedFromIdentity;
      var formData = _ref.formData;

      name = typeof name === 'string' ? name : '';
      description = typeof description === 'string' ? description : '';
      var timezone = this.get('systemSettings').getSetting('timeZone');

      var bookingData = {
        name: name.trim(),
        description: description.trim(),
        startDateTime: startDateTime.startOf('minute').toISOString(),
        endDateTime: endDateTime.startOf('minute').toISOString(),
        timezone: moment().tz(timezone).format('Z'),
        additionalEmailAddresses: additionalEmailAddresses,
        owner: owner,
        bookerName: bookerName,
        bookerEmailAddress: bookerEmailAddress,
        resources: resources.map(function (resource) {
          return {
            identity: _ember['default'].get(resource, 'id'),
            resourceRestrictionId: _ember['default'].get(resource, 'resourceRestrictionId'),
            isPrimary: _ember['default'].get(resource, 'isPrimary') };
        }),
        recurrencePattern: recurrencePattern,
        bookingRequestFormValue: formData,
        source: bookingSource || 'RB',
        copiedFromIdentity: copiedFromIdentity
      };

      return this.get('nestedResourceStore').createResource({
        type: 'bookingType.bookingRequest',
        parentId: bookingTypeId,
        data: bookingData
      });
    }
  });
});