define("scientia-resourcebooker/pods/components/resource-tree/selection-list/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 8,
                      "column": 14
                    },
                    "end": {
                      "line": 14,
                      "column": 14
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/resource-tree/selection-list/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  var el2 = dom.createTextNode("\n                   ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element4 = dom.childAt(fragment, [1]);
                  var morphs = new Array(4);
                  morphs[0] = dom.createAttrMorph(element4, 'class');
                  morphs[1] = dom.createAttrMorph(element4, 'title');
                  morphs[2] = dom.createElementMorph(element4);
                  morphs[3] = dom.createMorphAt(element4, 1, 1);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["button button--inlineLink button--star resourceTreeSelectionList-item-makePrimary ", ["subexpr", "if", [["get", "item.isPrimary", ["loc", [null, [10, 110], [10, 124]]], 0, 0, 0, 0], "button--star--active"], [], ["loc", [null, [10, 105], [10, 149]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "title", ["subexpr", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [11, 42], [11, 54]]], 0, 0, 0, 0], ".", "make_primary"], [], ["loc", [null, [11, 26], [11, 74]]], 0, 0]], [], ["loc", [null, [null, null], [11, 76]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["makePrimary", ["get", "item", ["loc", [null, [9, 47], [9, 51]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 24], [9, 53]]], 0, 0], ["inline", "inline-svg", ["icons/star.svg"], ["class", "button--icon"], ["loc", [null, [12, 19], [12, 71]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 16,
                      "column": 14
                    },
                    "end": {
                      "line": 16,
                      "column": 174
                    }
                  },
                  "moduleName": "scientia-resourcebooker/pods/components/resource-tree/selection-list/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "resourceTreeSelectionList-itemIcon");
                  var el2 = dom.createElement("img");
                  dom.setAttribute(el2, "alt", "");
                  dom.setAttribute(el2, "class", "resourceTreeSelectionList-itemIcon-icon");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element3 = dom.childAt(fragment, [0, 0]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createAttrMorph(element3, 'src');
                  return morphs;
                },
                statements: [["attribute", "src", ["get", "item.imageUri", ["loc", [null, [16, 102], [16, 115]]], 0, 0, 0, 0], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 6,
                    "column": 10
                  },
                  "end": {
                    "line": 20,
                    "column": 10
                  }
                },
                "moduleName": "scientia-resourcebooker/pods/components/resource-tree/selection-list/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                dom.setAttribute(el1, "class", "resourceTreeSelectionList-item");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("button");
                dom.setAttribute(el2, "type", "button");
                dom.setAttribute(el2, "class", "resourceTreeSelectionList-item-remove button button--link button--icon at-removeIcon");
                var el3 = dom.createTextNode("✕");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element5 = dom.childAt(fragment, [1]);
                var element6 = dom.childAt(element5, [3]);
                var element7 = dom.childAt(element5, [7]);
                var morphs = new Array(7);
                morphs[0] = dom.createMorphAt(element5, 1, 1);
                morphs[1] = dom.createAttrMorph(element6, 'disabled');
                morphs[2] = dom.createElementMorph(element6);
                morphs[3] = dom.createMorphAt(element5, 5, 5);
                morphs[4] = dom.createAttrMorph(element7, 'title');
                morphs[5] = dom.createAttrMorph(element7, 'class');
                morphs[6] = dom.createMorphAt(element7, 0, 0);
                return morphs;
              },
              statements: [["block", "if", [["get", "enablePrimary", ["loc", [null, [8, 20], [8, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [8, 14], [14, 21]]]], ["attribute", "disabled", ["get", "disabled", ["loc", [null, [15, 33], [15, 41]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["get", "onRemove", ["loc", [null, [15, 53], [15, 61]]], 0, 0, 0, 0], ["get", "item", ["loc", [null, [15, 62], [15, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [15, 44], [15, 68]]], 0, 0], ["block", "if", [["get", "item.imageUri", ["loc", [null, [16, 20], [16, 33]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [16, 14], [16, 181]]]], ["attribute", "title", ["get", "item.imageTitle", ["loc", [null, [17, 28], [17, 43]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["resourceTreeSelectionList-itemName ", ["subexpr", "if", [["get", "item.isPrimary", ["loc", [null, [17, 93], [17, 107]]], 0, 0, 0, 0], "resourceTreeSelectionList-itemName--primary", ""], [], ["loc", [null, [17, 88], [17, 158]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "item.name", ["loc", [null, [17, 160], [17, 173]]], 0, 0, 0, 0]],
              locals: ["item"],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 3,
                  "column": 8
                },
                "end": {
                  "line": 22,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/resource-tree/selection-list/template.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h3");
              dom.setAttribute(el1, "class", "resourceTreeSelectionList-header");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" (");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(") ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "containerElement--padded");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element8, 0, 0);
              morphs[1] = dom.createMorphAt(element8, 2, 2);
              morphs[2] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
              return morphs;
            },
            statements: [["inline", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [4, 73], [4, 85]]], 0, 0, 0, 0], ".", ["subexpr", "to-lower", [["get", "category", ["loc", [null, [4, 100], [4, 108]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 90], [4, 109]]], 0, 0]], [], ["loc", [null, [4, 57], [4, 110]]], 0, 0]], [], ["loc", [null, [4, 53], [4, 112]]], 0, 0], ["content", "items.length", ["loc", [null, [4, 114], [4, 130]]], 0, 0, 0, 0], ["block", "each", [["subexpr", "sort-by", [["get", "sortProperty", ["loc", [null, [6, 27], [6, 39]]], 0, 0, 0, 0], ["get", "items", ["loc", [null, [6, 40], [6, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 18], [6, 46]]], 0, 0]], [], 0, null, ["loc", [null, [6, 10], [20, 19]]]]],
            locals: ["category", "items"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 6
              },
              "end": {
                "line": 23,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resource-tree/selection-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each-in", [["subexpr", "group-by", ["type", ["subexpr", "sort-by", ["rank", ["get", "model", ["loc", [null, [3, 52], [3, 57]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 36], [3, 58]]], 0, 0]], [], ["loc", [null, [3, 19], [3, 59]]], 0, 0]], [], 0, null, ["loc", [null, [3, 8], [22, 20]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 6
              },
              "end": {
                "line": 26,
                "column": 6
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resource-tree/selection-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "resourceTree-sidebar-note");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [25, 65], [25, 77]]], 0, 0, 0, 0], ".", "no_items"], [], ["loc", [null, [25, 49], [25, 93]]], 0, 0]], [], ["loc", [null, [25, 45], [25, 95]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 36,
                  "column": 8
                },
                "end": {
                  "line": 40,
                  "column": 8
                }
              },
              "moduleName": "scientia-resourcebooker/pods/components/resource-tree/selection-list/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "button button--white");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element0, 'disabled');
              morphs[1] = dom.createElementMorph(element0);
              morphs[2] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "disabled", ["get", "disabled", ["loc", [null, [37, 85], [37, 93]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "action", [["get", "onSort", ["loc", [null, [37, 35], [37, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 27], [37, 42]]], 0, 0]], [], ["loc", [null, [37, 18], [37, 44]]], 0, 0], ["inline", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [38, 38], [38, 50]]], 0, 0, 0, 0], ".", "sort"], [], ["loc", [null, [38, 22], [38, 62]]], 0, 0]], [], ["loc", [null, [38, 18], [38, 64]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 6
              },
              "end": {
                "line": 47,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resource-tree/selection-list/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "resourceTreeSelectionList-actions");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("         ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "class", "button button--white");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [2]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element2, 'disabled');
            morphs[1] = dom.createElementMorph(element2);
            morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
            morphs[3] = dom.createMorphAt(element1, 4, 4);
            return morphs;
          },
          statements: [["attribute", "disabled", ["get", "disabled", ["loc", [null, [31, 85], [31, 93]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "action", [["get", "onClear", ["loc", [null, [31, 34], [31, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [31, 26], [31, 42]]], 0, 0]], [], ["loc", [null, [31, 17], [31, 44]]], 0, 0], ["inline", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [32, 38], [32, 50]]], 0, 0, 0, 0], ".", "clear"], [], ["loc", [null, [32, 22], [32, 63]]], 0, 0]], [], ["loc", [null, [32, 18], [32, 65]]], 0, 0], ["block", "if", [["get", "showSortButton", ["loc", [null, [36, 14], [36, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [36, 8], [40, 15]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 49,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resource-tree/selection-list/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "model.length", ["loc", [null, [2, 12], [2, 24]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 6], [26, 13]]]], ["block", "if", [["get", "model.length", ["loc", [null, [28, 12], [28, 24]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [28, 6], [47, 11]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 50,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/resource-tree/selection-list/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "container-element", [], ["padded", true, "title", ["subexpr", "if", [["get", "title", ["loc", [null, [1, 43], [1, 48]]], 0, 0, 0, 0], ["get", "title", ["loc", [null, [1, 49], [1, 54]]], 0, 0, 0, 0], ["subexpr", "t", [["subexpr", "concat-strings", [["get", "localePrefix", ["loc", [null, [1, 74], [1, 86]]], 0, 0, 0, 0], ".", "selection"], [], ["loc", [null, [1, 58], [1, 103]]], 0, 0]], [], ["loc", [null, [1, 55], [1, 104]]], 0, 0]], [], ["loc", [null, [1, 39], [1, 105]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [49, 22]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});