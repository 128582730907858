define("scientia-resourcebooker/pods/logged-in/booking-request/index/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["calendar-download"], ["showIcalRegenerateTokenModal", ["subexpr", "action", ["showIcalRegenerateTokenModal"], [], ["loc", [null, [8, 35], [8, 74]]], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showDownloadCalendar", ["loc", [null, [9, 25], [9, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 20], [9, 46]]], 0, 0], false], [], ["loc", [null, [9, 12], [9, 53]]], 0, 0]], ["loc", [null, [6, 9], [9, 54]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showDownloadCalendar", ["loc", [null, [10, 32], [10, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 27], [10, 53]]], 0, 0], false], [], ["loc", [null, [10, 19], [10, 60]]], 0, 0]], ["loc", [null, [5, 7], [10, 61]]], 0, 0]], ["loc", [null, [3, 2], [11, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 0
            },
            "end": {
              "line": 26,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["confirmMessage", ["subexpr", "t", ["components.calendar_modal.regenerate_subscription_confirm"], [], ["loc", [null, [20, 23], [20, 86]]], 0, 0], "confirmTitle", ["subexpr", "t", ["components.calendar_modal.regenerate_subscription_title"], [], ["loc", [null, [21, 21], [21, 82]]], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showIcalRegenerateTokenModal", ["loc", [null, [22, 27], [22, 55]]], 0, 0, 0, 0], false], [], ["loc", [null, [22, 22], [22, 62]]], 0, 0]], [], ["loc", [null, [22, 14], [22, 63]]], 0, 0], "confirm", ["subexpr", "action", ["regenerateTokenConfirm"], [], ["loc", [null, [23, 16], [23, 49]]], 0, 0]], ["loc", [null, [18, 11], [23, 50]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [24, 39], [24, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 29], [24, 44]]], 0, 0]], [], ["loc", [null, [24, 21], [24, 45]]], 0, 0]], ["loc", [null, [17, 9], [24, 46]]], 0, 0]], ["loc", [null, [15, 4], [25, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 0
            },
            "end": {
              "line": 43,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-request-overview"], ["model", ["subexpr", "@mut", [["get", "showBookingRequest", ["loc", [null, [34, 12], [34, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "showBookingRequestActions", ["subexpr", "@mut", [["get", "showBookingRequestActions", ["loc", [null, [35, 32], [35, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showBookingRequest", ["loc", [null, [36, 25], [36, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [36, 20], [36, 44]]], 0, 0], false], [], ["loc", [null, [36, 12], [36, 51]]], 0, 0], "cancelBooking", ["subexpr", "action", ["cancelBookingRequest"], [], ["loc", [null, [37, 20], [37, 51]]], 0, 0], "editBooking", ["subexpr", "action", ["editBookingRequest"], [], ["loc", [null, [38, 18], [38, 47]]], 0, 0], "copyBooking", ["subexpr", "action", ["copyBookingRequest"], [], ["loc", [null, [39, 18], [39, 47]]], 0, 0]], ["loc", [null, [32, 9], [39, 48]]], 0, 0], "dialogClass", "bookingRequestOverview", "dialogDescription", ["subexpr", "t", ["pods.logged_in.booking_request.index.booking_details_description"], [], ["loc", [null, [41, 22], [41, 92]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showBookingRequest", ["loc", [null, [42, 32], [42, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [42, 27], [42, 51]]], 0, 0], false], [], ["loc", [null, [42, 19], [42, 58]]], 0, 0]], ["loc", [null, [31, 7], [42, 59]]], 0, 0]], ["loc", [null, [29, 2], [42, 61]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 45,
              "column": 0
            },
            "end": {
              "line": 54,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-request-edit-restrictions-modal"], ["model", ["subexpr", "@mut", [["get", "editRestrictionsModal", ["loc", [null, [51, 12], [51, 33]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [49, 9], [51, 34]]], 0, 0]], ["loc", [null, [48, 7], [51, 35]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [52, 37], [52, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [52, 27], [52, 42]]], 0, 0]], [], ["loc", [null, [52, 19], [52, 43]]], 0, 0]], ["loc", [null, [46, 2], [53, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 0
            },
            "end": {
              "line": 68,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["booking-cancellation-modal"], ["model", ["subexpr", "@mut", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [62, 12], [62, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "close", ["subexpr", "action", [["subexpr", "mut", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [63, 25], [63, 63]]], 0, 0, 0, 0]], [], ["loc", [null, [63, 20], [63, 64]]], 0, 0], null], [], ["loc", [null, [63, 12], [63, 70]]], 0, 0], "confirm", ["subexpr", "action", ["confirmBookingRequestCancellation"], [], ["loc", [null, [64, 14], [64, 58]]], 0, 0]], ["loc", [null, [60, 9], [64, 59]]], 0, 0], "dialogDescription", ["subexpr", "t", ["pods.logged_in.booking_request.index.cancel_modal_description"], [], ["loc", [null, [65, 22], [65, 89]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [66, 32], [66, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [66, 27], [66, 71]]], 0, 0], null], [], ["loc", [null, [66, 19], [66, 77]]], 0, 0]], ["loc", [null, [59, 7], [66, 78]]], 0, 0]], ["loc", [null, [57, 2], [67, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 70,
              "column": 0
            },
            "end": {
              "line": 83,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["confirm-modal"], ["close", ["subexpr", "action", [["subexpr", "mut", [["get", "showCannotEditDueToRecurringModal", ["loc", [null, [76, 25], [76, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [76, 20], [76, 59]]], 0, 0], false], [], ["loc", [null, [76, 12], [76, 66]]], 0, 0], "confirmTitle", ["subexpr", "t", ["components.booking_request_overview.cannot_edit_due_to_recurring_title"], [], ["loc", [null, [77, 19], [77, 95]]], 0, 0], "singleButtonMode", true, "confirmMessage", ["subexpr", "t", ["components.booking_request_overview.cannot_edit_due_to_recurring_message"], [], ["loc", [null, [79, 21], [79, 99]]], 0, 0], "confirm", ["subexpr", "action", [["subexpr", "mut", [["get", "showCannotEditDueToRecurringModal", ["loc", [null, [80, 27], [80, 60]]], 0, 0, 0, 0]], [], ["loc", [null, [80, 22], [80, 61]]], 0, 0], false], [], ["loc", [null, [80, 14], [80, 68]]], 0, 0]], ["loc", [null, [74, 9], [80, 69]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showCannotEditDueToRecurringModal", ["loc", [null, [81, 32], [81, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [81, 27], [81, 66]]], 0, 0], null], [], ["loc", [null, [81, 19], [81, 72]]], 0, 0]], ["loc", [null, [73, 7], [81, 73]]], 0, 0]], ["loc", [null, [71, 2], [82, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 85,
              "column": 0
            },
            "end": {
              "line": 98,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "to-elsewhere", [], ["named", "modal", "send", ["subexpr", "hash", [], ["body", ["subexpr", "component", ["rb-calendar-day-view"], ["withBookingRequest", ["subexpr", "@mut", [["get", "withBookingRequest", ["loc", [null, [90, 25], [90, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "displayed-date", ["subexpr", "@mut", [["get", "displayedDate", ["loc", [null, [91, 21], [91, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "calendar-events", ["subexpr", "@mut", [["get", "calendarEvents", ["loc", [null, [92, 22], [92, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "titlePath", "nameWithPrimaryResourceNames", "close", ["subexpr", "action", [["subexpr", "mut", [["get", "showDayViewCalendarModal", ["loc", [null, [94, 25], [94, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [94, 20], [94, 50]]], 0, 0], null], [], ["loc", [null, [94, 12], [94, 56]]], 0, 0], "on-event-click", ["subexpr", "action", ["openBookingRequestOverview"], [], ["loc", [null, [95, 21], [95, 58]]], 0, 0]], ["loc", [null, [88, 9], [95, 59]]], 0, 0], "onOutsideClick", ["subexpr", "action", [["subexpr", "mut", [["get", "showDayViewCalendarModal", ["loc", [null, [96, 32], [96, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [96, 27], [96, 57]]], 0, 0], null], [], ["loc", [null, [96, 19], [96, 63]]], 0, 0]], ["loc", [null, [87, 7], [96, 64]]], 0, 0]], ["loc", [null, [86, 2], [97, 6]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child7 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 119,
                  "column": 10
                },
                "end": {
                  "line": 121,
                  "column": 10
                }
              },
              "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "button-print", [], ["onClick", ["subexpr", "action", ["onPrintClick"], [], ["loc", [null, [120, 35], [120, 58]]], 0, 0], "location", "at-bookingRequestsIndex"], ["loc", [null, [120, 12], [120, 95]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 118,
                "column": 8
              },
              "end": {
                "line": 127,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "unless", [["get", "disablePrint", ["loc", [null, [119, 20], [119, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [119, 10], [121, 21]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 131,
                "column": 8
              },
              "end": {
                "line": 133,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "button button--primary at-downloadCalendar");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element4);
            morphs[1] = dom.createMorphAt(element4, 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
            return morphs;
          },
          statements: [["element", "action", ["showDownloadCalendar"], [], ["loc", [null, [132, 18], [132, 51]]], 0, 0], ["inline", "inline-svg", ["icons/download.svg"], ["class", "at-downloadCalendar-icon"], ["loc", [null, [132, 103], [132, 171]]], 0, 0], ["inline", "t", ["pods.logged_in.booking_request.index.subscribe"], [], ["loc", [null, [132, 177], [132, 231]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 135,
                "column": 8
              },
              "end": {
                "line": 135,
                "column": 48
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "help-trigger", ["loc", [null, [135, 32], [135, 48]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 103,
              "column": 4
            },
            "end": {
              "line": 138,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "sectionTitle--align-right at-bookingRequest");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "sr-only");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(element5, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element6, 3, 3);
          morphs[2] = dom.createMorphAt(element5, 3, 3);
          morphs[3] = dom.createMorphAt(element5, 6, 6);
          morphs[4] = dom.createMorphAt(element5, 8, 8);
          return morphs;
        },
        statements: [["inline", "t", ["pods.logged_in.booking_request.index.search_my_bookings"], [], ["loc", [null, [114, 32], [114, 95]]], 0, 0], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "boundQuery", ["loc", [null, [115, 24], [115, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "enter", ["subexpr", "action", ["submitSearch"], [], ["loc", [null, [115, 41], [115, 64]]], 0, 0], "placeholder", ["subexpr", "t", ["pods.logged_in.booking_request.index.search_my_bookings"], [], ["loc", [null, [115, 77], [115, 138]]], 0, 0], "class", "form-input form-input--white sectionTitle-search-input at-bookingRequest"], ["loc", [null, [115, 10], [115, 221]]], 0, 0], ["block", "unless", [["get", "media.isMobile", ["loc", [null, [118, 18], [118, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [118, 8], [127, 19]]]], ["block", "unless", [["get", "icalDisableBookings", ["loc", [null, [131, 18], [131, 37]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [131, 8], [133, 19]]]], ["block", "unless", [["get", "media.isTiny", ["loc", [null, [135, 18], [135, 30]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [135, 8], [135, 59]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child8 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 150,
                "column": 8
              },
              "end": {
                "line": 153,
                "column": 8
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["inline", "rb-agenda-view", [], ["results", ["subexpr", "@mut", [["get", "model.searchResults.results", ["loc", [null, [151, 35], [151, 62]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "at-bookingRequest", "onEventClick", "openBookingRequestOverview"], ["loc", [null, [151, 10], [151, 132]]], 0, 0], ["inline", "resources-meta", [], ["currentPage", ["subexpr", "@mut", [["get", "model.searchResults.resourcesMeta.currentPage", ["loc", [null, [152, 39], [152, 84]]], 0, 0, 0, 0]], [], [], 0, 0], "itemsPerPage", ["subexpr", "@mut", [["get", "perPage", ["loc", [null, [152, 98], [152, 105]]], 0, 0, 0, 0]], [], [], 0, 0], "changePage", "changePage", "totalPages", ["subexpr", "@mut", [["get", "model.searchResults.resourcesMeta.totalPages", ["loc", [null, [152, 141], [152, 185]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [152, 10], [152, 187]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 140,
              "column": 4
            },
            "end": {
              "line": 155,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "bookingRequestSearch");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2, "class", "bookingRequestSearch-title");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("em");
          dom.setAttribute(el4, "class", "bookingRequestSearch-title-query");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "class", "button");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(element2, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
          morphs[2] = dom.createElementMorph(element3);
          morphs[3] = dom.createMorphAt(element3, 0, 0);
          morphs[4] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["pods.logged_in.booking_request.index.results_for"], [], ["loc", [null, [144, 12], [144, 68]]], 0, 0], ["content", "query", ["loc", [null, [145, 57], [145, 66]]], 0, 0, 0, 0], ["element", "action", ["clearSearch"], [], ["loc", [null, [147, 18], [147, 42]]], 0, 0], ["inline", "t", ["pods.logged_in.booking_request.index.clear_search"], [], ["loc", [null, [147, 58], [147, 115]]], 0, 0], ["block", "if", [["get", "model.searchResults.results.length", ["loc", [null, [150, 14], [150, 48]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [150, 8], [153, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child9 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 155,
                "column": 4
              },
              "end": {
                "line": 159,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "emptyContainer");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", ["pods.logged_in.booking_request.index.searchLoading"], [], ["loc", [null, [157, 8], [157, 66]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 161,
                  "column": 6
                },
                "end": {
                  "line": 178,
                  "column": 6
                }
              },
              "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "rb-calendar", [], ["displayed-date", ["subexpr", "@mut", [["get", "displayedDate", ["loc", [null, [163, 25], [163, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "location", "at-resource", "calendarEvents", ["subexpr", "@mut", [["get", "model.bookings", ["loc", [null, [165, 25], [165, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "event-id-path", "id", "event-title-path", "nameWithPrimaryResourceNames", "event-from-time-path", "startDateTime", "event-to-time-path", "endDateTime", "event-tag-path", "status", "show-tag-button", false, "on-view-type-changed", ["subexpr", "action", ["onViewTypeChanged"], [], ["loc", [null, [172, 31], [172, 59]]], 0, 0], "on-event-click", "openBookingRequestOverview", "on-event-count-badge-click", "onClickCountBadge", "on-displayed-date-change", "setDisplayedDate", "on-sheet-change", "fetchBookingRequests"], ["loc", [null, [162, 8], [177, 10]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 159,
                "column": 4
              },
              "end": {
                "line": 180,
                "column": 4
              }
            },
            "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["get", "canShowCalendar", ["loc", [null, [161, 12], [161, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [161, 6], [178, 13]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 155,
              "column": 4
            },
            "end": {
              "line": 180,
              "column": 4
            }
          },
          "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "showSearchLoading", ["loc", [null, [155, 14], [155, 31]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [155, 4], [180, 4]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 183,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/logged-in/booking-request/index/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("main");
        dom.setAttribute(el1, "class", "container-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "role", "region");
        dom.setAttribute(el2, "tabindex", "0");
        dom.setAttribute(el2, "id", "main");
        dom.setAttribute(el2, "id", "main-section");
        dom.setAttribute(el2, "class", "container-content at-bookingRequest");
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element7 = dom.childAt(fragment, [15, 1]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 9, 9, contextualElement);
        morphs[5] = dom.createMorphAt(fragment, 11, 11, contextualElement);
        morphs[6] = dom.createMorphAt(fragment, 13, 13, contextualElement);
        morphs[7] = dom.createAttrMorph(element7, 'aria-label');
        morphs[8] = dom.createMorphAt(element7, 1, 1);
        morphs[9] = dom.createMorphAt(element7, 3, 3);
        return morphs;
      },
      statements: [["block", "if", [["get", "showDownloadCalendar", ["loc", [null, [2, 6], [2, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [12, 7]]]], ["block", "if", [["get", "showIcalRegenerateTokenModal", ["loc", [null, [14, 6], [14, 34]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [14, 0], [26, 7]]]], ["block", "if", [["get", "showBookingRequest", ["loc", [null, [28, 6], [28, 24]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [28, 0], [43, 7]]]], ["block", "if", [["get", "editRestrictionsModal", ["loc", [null, [45, 6], [45, 27]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [45, 0], [54, 7]]]], ["block", "if", [["get", "bookingRequestScheduledForCancellation", ["loc", [null, [56, 6], [56, 44]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [56, 0], [68, 7]]]], ["block", "if", [["get", "showCannotEditDueToRecurringModal", ["loc", [null, [70, 6], [70, 39]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [70, 0], [83, 7]]]], ["block", "if", [["get", "showDayViewCalendarModal", ["loc", [null, [85, 6], [85, 30]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [85, 0], [98, 7]]]], ["attribute", "aria-label", ["concat", [["subexpr", "t", ["general.main_section"], [], ["loc", [null, [101, 74], [101, 102]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "section-title", [], ["icon", "icons/calendar.svg", "text", ["subexpr", "t", ["pods.logged_in.booking_request.index.my_bookings"], [], ["loc", [null, [105, 9], [105, 63]]], 0, 0], "actionIcon", "icons/refresh.svg", "actionTitle", ["subexpr", "t", ["pods.logged_in.booking_request.index.refresh_bookings"], [], ["loc", [null, [108, 16], [108, 75]]], 0, 0], "clickAction", "reloadView", "location", "at-bookingRequest"], 7, null, ["loc", [null, [103, 4], [138, 22]]]], ["block", "if", [["get", "showSearchResults", ["loc", [null, [140, 10], [140, 27]]], 0, 0, 0, 0]], [], 8, 9, ["loc", [null, [140, 4], [180, 11]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
    };
  })());
});