define("scientia-resourcebooker/pods/components/toggle-button/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/toggle-button/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "role", "checkbox");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'value');
        morphs[2] = dom.createAttrMorph(element0, 'aria-checked');
        morphs[3] = dom.createAttrMorph(element0, 'aria-label');
        morphs[4] = dom.createAttrMorph(element0, 'disabled');
        morphs[5] = dom.createElementMorph(element0);
        morphs[6] = dom.createMorphAt(element0, 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["toggleButton ", ["subexpr", "if", [["get", "isActive", ["loc", [null, [2, 27], [2, 35]]], 0, 0, 0, 0], "toggleButton--active"], [], ["loc", [null, [2, 22], [2, 60]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "value", ["concat", [["get", "elementId", ["loc", [null, [3, 11], [3, 20]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "aria-checked", ["concat", [["get", "isActive", ["loc", [null, [5, 18], [5, 26]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "aria-label", ["concat", [["subexpr", "if", [["get", "ariaLabel", ["loc", [null, [6, 19], [6, 28]]], 0, 0, 0, 0], ["get", "ariaLabel", ["loc", [null, [6, 29], [6, 38]]], 0, 0, 0, 0], ["get", "option.label", ["loc", [null, [6, 39], [6, 51]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 14], [6, 53]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["get", "disabled", ["loc", [null, [7, 33], [7, 41]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["toggle"], [], ["loc", [null, [7, 2], [7, 21]]], 0, 0], ["content", "option.label", ["loc", [null, [8, 2], [8, 18]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});