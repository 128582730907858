define("scientia-resourcebooker/pods/components/resource-tree/search-results/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 5,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resource-tree/search-results/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h2");
            dom.setAttribute(el1, "class", "resourceTree-searchResults-header");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", [["subexpr", "concat-strings", ["components.resource_tree.", ["subexpr", "to-lower", [["get", "type", ["loc", [null, [4, 108], [4, 112]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 98], [4, 113]]], 0, 0]], [], ["loc", [null, [4, 54], [4, 114]]], 0, 0]], [], ["loc", [null, [4, 50], [4, 116]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 2
              },
              "end": {
                "line": 15,
                "column": 2
              }
            },
            "moduleName": "scientia-resourcebooker/pods/components/resource-tree/search-results/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "resource-tree/item", [], ["model", ["subexpr", "unbound", [["get", "item", ["loc", [null, [10, 23], [10, 27]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 14], [10, 28]]], 0, 0], "selected", ["subexpr", "@mut", [["get", "selected", ["loc", [null, [11, 17], [11, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "paginate", ["subexpr", "action", [["subexpr", "optional", [["get", "noop", ["loc", [null, [12, 35], [12, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 25], [12, 40]]], 0, 0]], [], ["loc", [null, [12, 17], [12, 41]]], 0, 0], "onSelect", ["subexpr", "action", [["get", "onSelect", ["loc", [null, [13, 25], [13, 33]]], 0, 0, 0, 0], ["subexpr", "to-lower", [["get", "type", ["loc", [null, [13, 44], [13, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 34], [13, 49]]], 0, 0]], [], ["loc", [null, [13, 17], [13, 50]]], 0, 0], "children", null], ["loc", [null, [9, 6], [14, 23]]], 0, 0]],
          locals: ["item"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 18,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resource-tree/search-results/template.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "resourceTree manageResourcesItemsList at-searchResults");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          return morphs;
        },
        statements: [["block", "unless", [["get", "hideHeaders", ["loc", [null, [3, 12], [3, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 2], [5, 13]]]], ["block", "each", [["get", "results", ["loc", [null, [8, 10], [8, 17]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [8, 2], [15, 11]]]]],
        locals: ["type", "results"],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 0
            },
            "end": {
              "line": 26,
              "column": 0
            }
          },
          "moduleName": "scientia-resourcebooker/pods/components/resource-tree/search-results/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "resourceTree-searchResults-empty");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "button button--white");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createMorphAt(element1, 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["components.resource_tree.no_results"], [], ["loc", [null, [22, 2], [22, 45]]], 0, 0], ["element", "action", [["get", "onClearSearch", ["loc", [null, [24, 17], [24, 30]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 8], [24, 32]]], 0, 0], ["inline", "t", ["components.resource_tree.clear_search"], [], ["loc", [null, [24, 62], [24, 107]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 27,
            "column": 0
          }
        },
        "moduleName": "scientia-resourcebooker/pods/components/resource-tree/search-results/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "each-in", [["get", "model", ["loc", [null, [1, 11], [1, 16]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [26, 12]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});