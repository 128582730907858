define('scientia-resourcebooker/pods/components/resources-list/items/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'ul',
    classNames: ['resourcesList-items'],
    attributeBindings: ['role'],
    role: 'list',

    resources: null,
    resourceGroups: null,
    groupId: null,
    bookingType: null,
    bookingModeIsRecurring: false,
    hideResourceGroupCount: false,
    isCopyMode: false,
    isConfiguration: false,
    concurrencyId: '',

    groupsListExpanded: true,

    // Which date to show in the URL for the resource. Either a date from the single tab or recurring
    calendarDate: _ember['default'].computed('firstDay', 'firstDayRecurring', 'bookingModeIsRecurring', function () {
      var value = null;

      try {
        value = this.get('bookingModeIsRecurring') ? this.get('firstDayRecurring').format('YYYY-MM-DD') : this.get('firstDay') ? this.get('firstDay') : null;
      } catch (err) {
        //no-op
      }

      return value;
    }),

    calendarFromTime: _ember['default'].computed('fromTime', 'fromTimeRecurring', 'bookingModeIsRecurring', function () {
      var value = null;

      try {
        return this.get('bookingModeIsRecurring') ? this.get('fromTimeRecurring') || '' : this.get('fromTime') ? this.get('fromTime') : '';
      } catch (err) {
        //no-op
      }

      return value;
    }),

    calendarToTime: _ember['default'].computed('toTime', 'toTimeRecurring', 'bookingModeIsRecurring', function () {
      var value = null;
      try {
        return this.get('bookingModeIsRecurring') ? this.get('toTimeRecurring') || '' : this.get('toTime') ? this.get('toTime') : '';
      } catch (err) {
        //no-op
      }

      return value;
    })

  });
});