define('scientia-resourcebooker/adapters/booking-type', ['exports', 'scientia-resourcebooker/adapters/application'], function (exports, _scientiaResourcebookerAdaptersApplication) {
  exports['default'] = _scientiaResourcebookerAdaptersApplication['default'].extend({

    // queryRecord needs to use a different endpoint when there is a query param "editFromPage: manage-resources / report".
    // This indicates that we are fetching the booking-type after clicking "Edit Booking" on the Resource Calendar / Report by someone
    // with "Manage Bookings" permission. Someone with "Manage Bookings" permission is allowed to edit a Booking however
    // if user only has that permission but no Show permission on the template, when the user is getting redirected to edit booking
    // the booking-type will fail to fetch due to no permissions on it. This new endpoint will make sure to validate the manage-bookings permision
    // See RB-385
    urlForQueryRecord: function urlForQueryRecord(query, modelName) {

      var baseUrl = this._super.apply(this, arguments);
      var url = baseUrl + ('/' + query.id);

      // If we are fetching the BookingType from Manage-Resources, simply return the normal URL
      if (query.manage) {
        delete query.id;
        delete query.manage;
        return url;
      }

      // If we are fetching BookingType when editing a booking via resource-calendar or report, use different endpoint
      var editFromPage = query && query.editFromPage;
      if (editFromPage === 'manage-resources' || editFromPage === 'report') {
        url = this._buildURL(modelName, query.id);
        url += '/ManageBookings/%@'.loc(query.resourceId);
        delete query.id;
        delete query.resourceId;
        delete query.editFromPage;
        return url;
      }

      // Else return stripped down version
      if (query && query.id) delete query.id;
      return url + '/ReadOnly';
    },

    urlForFindRecord: function urlForFindRecord() {
      var url = this._super.apply(this, arguments);
      return url + '/ReadOnly';
    },

    // For FindAll we never require full objects, so using ReadOnly endpoint.
    urlForFindAll: function urlForFindAll() {
      var url = this._super.apply(this, arguments);
      return url + '/ReadOnly';
    }
  });
});