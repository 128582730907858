define('scientia-resourcebooker/pods/components/booking-request-overview/component', ['exports', 'ember', 'scientia-resourcebooker/config/environment', 'scientia-resourcebooker/mixins/modal-dialog', 'ember-can'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment, _scientiaResourcebookerMixinsModalDialog, _emberCan) {
  exports['default'] = _ember['default'].Component.extend(_scientiaResourcebookerMixinsModalDialog['default'], _emberCan.CanMixin, {
    classNames: ['bookingRequestOverview'],
    classNameBindings: ['isShowingFormData:at-bookingHistory', 'location'],

    bookingTypeName: _ember['default'].computed.alias('model.bookingType.name'),
    showBookingRequestActions: true,

    notificationHistoryFetcher: _ember['default'].inject.service(),
    bookingRequestHistoryFetcher: _ember['default'].inject.service(),

    recurrenceProcessor: _ember['default'].inject.service(),
    eventOccurrencesFetcher: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    customEvents: _ember['default'].inject.service(),

    canViewNotificationHistory: (function () {
      if (this.get('systemSettings').getSetting('bookingHistoryAdminOnly')) {
        return this.can('access admin');
      } else {
        return true;
      }
    }).property('systemSettings.options.bookingHistoryAdminOnly'),

    timezone: _ember['default'].computed.alias('systemSettings.options.timeZone'),

    isShowingNotificationHistory: false,
    isLoadingNotificationHistory: false,

    isShowingOccurrences: false,

    disablePrint: _ember['default'].computed.alias('systemSettings.options.disableBookingSummaryPrint'),

    attachmentDownloadURL: _scientiaResourcebookerConfigEnvironment['default'].API.BOOKING_FORM_FILE_DOWNLOAD,

    hasRecurrence: _ember['default'].computed.alias('modelCurrent.recurrencePattern'),
    hasExternallyManagedResources: _ember['default'].computed.filterBy('model.resources', 'externalApplicationId'),
    occurrences: [],

    shouldShowHistoryDropdown: _ember['default'].computed.gt('model.historyCount', 1),

    bookingHistory: [],
    bookingHistorySelected: null,

    modelCurrent: _ember['default'].computed('bookingHistorySelected.value', 'model', function () {
      var index = this.getWithDefault('bookingHistorySelected.value', 0);
      if (index === 0) {
        return this.get('model');
      } else {
        return this.get('bookingHistory').objectAt(index);
      }
    }),

    occurrencesCurrent: _ember['default'].computed('bookingHistorySelected.value', 'occurrences.[]', function () {
      var index = this.getWithDefault('bookingHistorySelected.value', 0);
      if (index === 0) {
        return this.get('occurrences');
      } else {
        return this.get('modelCurrent.occurrences');
      }
    }),

    isViewingBookingHistoryFirstRevision: _ember['default'].computed('bookingHistorySelected.value', function () {
      if (!this.get('bookingHistorySelected')) return false;
      return this.get('bookingHistorySelected.value') + 1 === this.get('bookingHistory.length');
    }),

    hideCopyBooking: false,
    disableCopyBooking: _ember['default'].computed.alias('systemSettings.options.disableCopyBooking'),

    // When popup loads fetch occurrences if its a recurring booking
    didInsertElement: function didInsertElement() {
      var _this = this;

      // Listen to clashModalClosed custom event and trigger callback
      this.get('customEvents').on('clashModalClosed', this, '_onClashModalClosed');

      var hash = {};

      if (this.get('model.hasRecurrence')) {
        hash.occurrences = this.get('eventOccurrencesFetcher').fetchSchedules(this.get('model.eventId'));
      }

      _ember['default'].RSVP.hash(hash).then(function (results) {
        if (_this.get('isDestroyed') || _this.get('isDestroying')) return;

        if (results.occurrences) {
          _this.set('occurrences', results.occurrences);
        }
      })['catch'](function (err) {
        LE.error('Could not load promises on booking-request-overview', err);
      });
    },

    willDestroyElement: function willDestroyElement() {
      this.get('customEvents').off('clashModalClosed', this, '_onClashModalClosed');
    },

    /**
     * Callback when the clash modal is closed, to trigger the refresh of the current booking
     * being displayed so that its status updates from eg. Approved to Cancelled
     */
    _onClashModalClosed: function _onClashModalClosed() {
      this.get('model').reload();
    },

    hasEditedOccurrences: _ember['default'].computed('occurrencesCurrent', 'bookingHistorySelected.value', function () {
      if (!this.get('occurrencesCurrent')) return false;
      return this.get('occurrencesCurrent').any(function (item) {
        return _ember['default'].get(item, 'isEdited');
      });
    }),

    hasEditedOccurrencesFirstThree: _ember['default'].computed('occurrencesCurrent', 'bookingHistorySelected.value', function () {
      if (!this.get('occurrencesCurrent')) return false;
      return this.get('occurrencesCurrent').slice(0, 3).any(function (item) {
        return _ember['default'].get(item, 'isEdited');
      });
    }),

    // Generate recurrence description from recurrencePattern
    recurrenceDescription: (function () {
      if (!this.get('modelCurrent.recurrencePattern')) return;
      var description = this.get('modelCurrent.recurrencePattern.recurrenceDescription');
      return description || this.get('recurrenceProcessor').generateDescription(this.get('modelCurrent.recurrencePattern'));
    }).property('modelCurrent.recurrencePattern', 'bookingHistorySelected.value'),

    bookingHistoryDates: _ember['default'].computed('bookingHistory.[]', function () {
      var _this2 = this;

      return this.get('bookingHistory').getEach('submissionDate').map(function (d, i) {
        return {
          value: i,
          label: moment(d).format(_this2.get('i18n').t('components.booking_request_overview.revision_date_format').toString())
        };
      });
    }),

    actions: {
      cancelBooking: function cancelBooking() {
        this.sendAction('cancelBooking', this.get('model'));
      },

      cancelSingleOccurrence: function cancelSingleOccurrence() {
        this.sendAction('cancelBooking', this.get('model'), 1);
      },

      cancelAllOccurrences: function cancelAllOccurrences() {
        this.sendAction('cancelBooking', this.get('model'), 2, this.get('occurrences.length'));
      },

      // second param is for singleOccurrence Mode
      editBooking: function editBooking() {
        var editRestrictions = this.get('model.editRestrictions');
        editRestrictions = editRestrictions ? editRestrictions.rejectBy('appliesToSingleOccurrenceUpdateOnly') : null;
        this.sendAction('editBooking', this.get('model'), false, editRestrictions, this.get('model.refittedResources'));
      },

      // second param is for singleOccurrence Mode
      editBookingSingleOccurrence: function editBookingSingleOccurrence() {
        var editRestrictions = this.get('model.editRestrictions');
        this.sendAction('editBooking', this.get('model'), true, editRestrictions, this.get('model.refittedResources'));
      },

      copyBooking: function copyBooking() {
        var editRestrictions = this.get('model.editRestrictions');
        editRestrictions = editRestrictions ? editRestrictions.rejectBy('appliesToSingleOccurrenceUpdateOnly') : null;
        this.sendAction('copyBooking', this.get('model'), false, editRestrictions, this.get('model.refittedResources'));
      },

      showNotificationHistory: function showNotificationHistory() {
        var _this3 = this;

        this.set('isLoadingNotificationHistory', true);
        this.get('notificationHistoryFetcher').fetch({ bookingId: this.get('model.eventId') }).then(function (notificationHistory) {
          if (_this3.get('isDestroyed')) return;
          _this3.set('notificationHistory', notificationHistory);
          _this3.set('isLoadingNotificationHistory', false);
          _this3.set('isShowingNotificationHistory', true);

          _ember['default'].run.next(_this3, function () {
            var $dialog = _ember['default'].$('.lf-dialog-content');
            if ($dialog && $dialog[0]) {
              $dialog.scrollTop($dialog[0].scrollHeight);
            }

            var element = document.querySelector('[data-focus-id="notificationHistoryList"]');
            if (element) element.focus();
          });
        })['catch'](function () {
          _this3.set('isLoadingNotificationHistory', false);
          _this3.set('isShowingNotificationHistory', false);
        });
      },

      hideNotificationHistory: function hideNotificationHistory() {
        this.set('isShowingNotificationHistory', false);
      },

      toggleOccurrences: function toggleOccurrences() {
        this.toggleProperty('isShowingOccurrences');
      },

      fetchBookingHistory: function fetchBookingHistory(query, deferred) {
        var _this4 = this;

        if (_ember['default'].isEmpty(this.get('bookingHistory'))) {

          this.get('bookingRequestHistoryFetcher').fetch({ bookingId: this.get('model.eventId') }).then(function (bookingHistory) {
            _this4.set('bookingHistory', bookingHistory);
            deferred.resolve(_this4.get('bookingHistoryDates'));
          });
        } else {
          deferred.resolve(this.get('bookingHistoryDates'));
        }
      }

    }
  });
});