define('scientia-resourcebooker/services/reports-processor', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    systemSettings: _ember['default'].inject.service(),
    timezone: _ember['default'].computed.alias('systemSettings.options.timeZone'),
    i18n: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    generate: function generate(_ref) {
      var _this = this;

      var data = _ref.data;
      var fromDate = _ref.fromDate;
      var toDate = _ref.toDate;
      var _ref$useBookingDates = _ref.useBookingDates;
      var useBookingDates = _ref$useBookingDates === undefined ? false : _ref$useBookingDates;

      var serializedData = this._serializeData(data);
      this.get('appInsights').trackEvent('report-audit-generate', {
        fromDate: fromDate.format(),
        toDate: toDate.format(),
        useBookingDates: useBookingDates,
        count: _ember['default'].get(data, 'length'),
        isAdmin: this.get('currentUser').isAdmin()
      });

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.REPORTS.AUDIT_GENERATE;

      // We want to pass local times so that we can generate a report with local times as well
      // We also need to encode because the format() function adds the timezone with the + char and it doesn't work with the api
      var formattedFromDate = encodeURIComponent(fromDate.format());
      var formattedToDate = encodeURIComponent(toDate.format());

      url += '?start=' + formattedFromDate + '&end=' + formattedToDate + '&useBookingDates=' + useBookingDates;

      var columnNames = ['identity', 'reference', 'title', 'description', 'startTimeUtc', 'endTimeUtc', 'startTimeLocal', 'endTimeLocal', 'durationMinutes', 'bookerId', 'bookerName', 'bookerEmailAddress', 'resourceType', 'resourceName', 'additionalEmailAddresses', 'templateName', 'currentStatus', 'statusAfterAction', 'action', 'actionDateUtc', 'actionDateLocal', 'actionedBy', 'notes', 'form', 'imported', 'importedBy', 'source', 'executionStartedUtc', 'executionStartedLocal', 'executionEndedUtc', 'executionEndedLocal', 'isPrimaryResource', 'copiedFromReference'];

      var translationPrefix = 'components.reports.audit.column_names.';
      var columnsTranslations = columnNames.reduce(function (memo, column) {
        var c = _this.get('i18n').t(translationPrefix + column);
        memo[column] = c ? c.toString() : column;
        return memo;
      }, {});

      this.get('session').stopAutoLogout();
      return this.get('authenticatedRequest').post(url, {
        columnHeaders: columnsTranslations,
        resourceControlNodes: serializedData,
        redactedDataValue: this.get('i18n').t(translationPrefix + 'redacted').toString()
      }, true);
    },

    getDownloadURL: function getDownloadURL(downloadKey) {

      var url = this.get('authenticatedRequest').getRootUrl() + _scientiaResourcebookerConfigEnvironment['default'].API.REPORTS.AUDIT_DOWNLOAD.loc(downloadKey);
      url += '?timezone=' + this.get('timezone');
      return url;
    },

    _serializeData: function _serializeData(data) {
      return data.map(function (d) {
        return {
          resourceTypeIdentity: d.resourceTypeId,
          resourceGroupIdentity: d.resourceGroupId,
          resourceIdentity: d.resourceId
        };
      });
    }

  });
});