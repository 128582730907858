define('scientia-resourcebooker/services/embedded-reports-fetcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment', 'ember-cli-string-helpers/helpers/truncate'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment, _emberCliStringHelpersHelpersTruncate) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    appInsights: _ember['default'].inject.service(),

    fetchAll: function fetchAll() {
      return this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.EMBEDDED_REPORTS.FEED).then(function (result) {
        return (result.availableReports || []).map(function (report) {
          report.name = (0, _emberCliStringHelpersHelpersTruncate.truncate)([report.name || '', 40]);
          return report;
        });
      });
    },

    fetchById: function fetchById(id) {
      var name = arguments.length <= 1 || arguments[1] === undefined ? '' : arguments[1];

      this.get('appInsights').trackEvent('report-dashboard-view', { reportId: id, reportName: name });
      return this.get('authenticatedRequest').fetch(_scientiaResourcebookerConfigEnvironment['default'].API.EMBEDDED_REPORTS.SINGLE.loc(id));
    }

  });
});