define('scientia-resourcebooker/pods/components/resources-list/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['resourcesList'],

    customFilters: null,
    customFiltersSelected: [],

    resources: null,
    resourceGroups: null,

    queryText: '',

    groupId: null,
    bookingType: null,
    isLoading: false,
    isCopyMode: false,
    isConfiguration: false,
    concurrencyId: '',

    isGroup: false,
    actions: {
      removeDefaultFilter: function removeDefaultFilter(filter) {
        this.sendAction('removeDefaultFilter', filter);
      },
      removeCustomFilter: function removeCustomFilter(filter) {
        this.sendAction('removeCustomFilter', filter);
      }
    }
  });
});