define('scientia-resourcebooker/services/additional-resources-searcher', ['exports', 'ember', 'scientia-resourcebooker/config/environment'], function (exports, _ember, _scientiaResourcebookerConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    authenticatedRequest: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    search: function search(_ref) {
      var bookingTypeId = _ref.bookingTypeId;
      var resourceRestrictionId = _ref.resourceRestrictionId;
      var query = _ref.query;
      var page = _ref.page;

      var url = _scientiaResourcebookerConfigEnvironment['default'].API.ADDITIONAL_RESOURCE_GROUP_FILTERER_URL;
      var data = { bookingTypeId: bookingTypeId, resourceRestrictionId: resourceRestrictionId, query: query, itemsPerPage: 20, pageNumber: page };

      return this.get('authenticatedRequest').post(url, data).then(this._normalizeResponse.bind(this));
    },

    _normalizeResponse: function _normalizeResponse(response) {
      response.results = response.results.map(function (r) {
        return _ember['default'].Object.create(r);
      });
      return response;
    }

  });
});