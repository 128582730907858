define('scientia-resourcebooker/pods/manage-resources/booking-type/edit/route', ['exports', 'ember', 'scientia-resourcebooker/mixins/flash-messages', 'scientia-resourcebooker/mixins/availability-form-route'], function (exports, _ember, _scientiaResourcebookerMixinsFlashMessages, _scientiaResourcebookerMixinsAvailabilityFormRoute) {
  exports['default'] = _ember['default'].Route.extend(_scientiaResourcebookerMixinsAvailabilityFormRoute['default'], _scientiaResourcebookerMixinsFlashMessages['default'], {

    appInsights: _ember['default'].inject.service(),
    authenticatedRequest: _ember['default'].inject.service(),

    can: _ember['default'].inject.service(),
    errorHandler: _ember['default'].inject.service(),

    rulesFetcher: _ember['default'].inject.service(),
    rulesProcessor: _ember['default'].inject.service(),

    permissionsProcessor: _ember['default'].inject.service(),
    permissionsValidator: _ember['default'].inject.service(),

    titleToken: function titleToken() {
      return this.get('i18n').t('pods.manage_resources.booking_type.edit.page_title');
    },

    tabs: { DESCRIPTION: 0, STATUS: 1, CALENDAR: 2, AVAILABILITY: 3, RESTRICTIONS: 4, PERMISSIONS: 5, NOTIFICATIONS: 6, RULES: 7 },

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        bookingType: this.get('store').queryRecord('booking-type', { id: params.id, manage: true }),
        bookingTypeRules: this.get('rulesFetcher').fetch('booking-type', params.id),
        availableRules: this.get('rulesFetcher').fetchRules(),
        events: [],
        previewEvents: [],
        eventPatterns: [],
        busyExceptions: [],
        linkedAvailability: []
      });
    },

    afterModel: function afterModel(model) {

      // Calling the mixin methods in run.next gives the controller enough time to compute the
      // properties required (modelId, parentId)
      _ember['default'].run.next(this, function () {
        this._fetchBusyExceptions();
        this._fetchLinkedUnavailability();
      });

      this.get('appInsights').trackPageView(this.get('routeName'), {
        id: model.bookingType.get('id'),
        name: model.bookingType.get('name')
      });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {

        this.resetAvailabilityProperties();

        controller.setProperties({
          selectedTab: 0,
          error: '',
          showDeleteRestrictionWarning: false,
          resourceRestrictionToDeleteDeferred: null
        });

        this.store.unloadAll('notification-template');
      }
    },

    _updateRules: function _updateRules() {
      var _this = this;

      var model = this.modelFor(this.routeName);
      var controller = this.controllerFor(this.routeName);
      return this.get('rulesProcessor').saveAllRules({
        model: model.bookingType,
        rules: controller.get('model.bookingTypeRules')
      })['catch'](function (error) {

        // Switch to Rules tab and rethrow error
        controller.set('selectedTab', _this.tabs.RULES);
        throw error;
      });
    },

    actions: {
      willTransition: function willTransition() {
        var model = this.modelFor('manage-resources.booking-type.edit');
        if (model.bookingType.get('hasDirtyAttributes')) model.bookingType.rollbackAttributes();
        model.bookingType.unloadRecord();
      },

      save: function save(transitionIndex) {
        var _this2 = this;

        var model = this.modelFor('manage-resources.booking-type.edit');
        var controller = this.controllerFor('manage-resources.booking-type.edit');
        var permissions = controller.get('permissions');
        var permissionsTypes = model.bookingType.get('permissionsKeys');

        controller.set('error', '');

        // Validate permissions
        if (!this._validatePermissions(permissions)) return false;

        var permissionFragments = this.get('permissionsProcessor').toModels(permissions, permissionsTypes);
        Object.keys(permissionFragments).forEach(function (type) {
          model.bookingType.get(type).setObjects(permissionFragments[type]);
        });

        // Validate name
        if (_ember['default'].isEmpty(model.bookingType.get('name').trim())) {
          return this._displayError(this.get('i18n').t('components.manage_resources.rules_form.flash_messages.name_required').toString(), this.tabs.DESCRIPTION);
        }

        // Validate Resource Restrictions
        var resourceRestrictions = model.bookingType.get('resourceRestrictionRules').filter(function (r) {
          return r.get('resourceType');
        });
        if (!this._validateResourceRestrictionRules(resourceRestrictions)) return;
        model.bookingType.set('resourceRestrictionRules', resourceRestrictions);

        // Validate rules
        if (this.get('can').can('edit-rules booking-type', { model: controller.get('model.bookingType') })) {
          var rules = controller.get('model.bookingTypeRules');
          if (!this._validateRules(rules)) return;
        }

        // Validate Calendar Options
        var calendarOptions = model.bookingType.get('calendarOptions');
        if (_ember['default'].get(calendarOptions, 'useCustomCalendarOptions')) {
          var startTime = _ember['default'].get(calendarOptions, 'calendarStartTime');
          var endTime = _ember['default'].get(calendarOptions, 'calendarEndTime');
          if (!startTime || !endTime) {
            return this._displayError(this.get('i18n').t('components.manage_resources.rules_form.flash_messages.calendar_times_required').toString(), this.tabs.CALENDAR);
          }
        }

        controller.set('isSaving', true);

        var promises = [model.bookingType.save()];

        if (this.get('can').can('edit-availability booking-type', { model: model.bookingType })) {
          promises.push(this._updateUnavailability());
        }

        if (this.get('can').can('edit-rules booking-type', { model: model.bookingType })) {
          promises.push(this._updateRules());
        }

        _ember['default'].RSVP.all(promises).then(function () {
          _this2.editSuccessFlash('booking-type');

          _this2.store.peekAll('resource-restriction-rule').filterBy('isNew').forEach(function (rule) {
            _this2.store.unloadRecord(rule);
          });

          if (transitionIndex) {
            return _this2.transitionTo('manage-resources.booking-type.index');
          } else {
            _this2.send('reload');
          }
        })['catch'](function (error) {
          var errorMessage = _this2.get('errorHandler').buildErrorMessage(error.responseJSON || error);
          if (errorMessage) {
            controller.set('error', errorMessage);
          } else {
            _this2.editErrorFlash('booking-type');
          }
        })['finally'](function () {
          controller.set('isSaving', false);
        });
      },

      reload: function reload() {

        this._reloadBookingType();

        this._fetchBusyExceptions();
        this.send('reloadAvailability');
      }

    },

    _reloadBookingType: function _reloadBookingType() {
      var _this3 = this;

      var id = this.modelFor(this.routeName).bookingType.id;
      this.get('store').queryRecord('booking-type', { id: id, manage: true }).then(function (bt) {
        _this3.set('model.bookingType', bt);
      });
    },

    _validatePermissions: function _validatePermissions(permissions) {
      var validate = this.get('permissionsValidator').validate(permissions);
      if (!validate.isValid) {
        this._displayError(this.get('i18n').t('components.manage_resources.permissions_form.permissions_validation_error').toString(), this.tabs.PERMISSIONS);
        return false;
      }

      return true;
    },

    // Check for any invalid rules
    _validateRules: function _validateRules(rules) {

      var errors = [];
      rules.forEach(function (rule) {
        var properties = rule.properties;
        properties.forEach(function (property, i, props) {
          var valueType = property.valueType.toLowerCase();
          var value = property.value;

          if (valueType === 'bool') {
            return; // Don't check boolean props as they are always false (true/false)
          } else if (valueType === 'number') {

              if (value === undefined || !Number.isInteger(parseInt(value, 10)) || parseInt(value, 10) < 0) {
                errors.pushObject({ ruleId: rule.id, propertyName: property.name });
              }
            } else if (valueType === 'date') {

              if (property.endRange) {
                var startRangeProperty = props.find(function (p) {
                  return p.startRange;
                });
                if (startRangeProperty) {
                  if (moment(startRangeProperty.value).isAfter(moment(property.value))) {
                    errors.pushObject({ ruleId: rule.id, propertyName: property.name });
                  }
                }
              }
            } else {
              if (_ember['default'].isEmpty(property.value) || !property.value) {
                errors.pushObject({ ruleId: rule.id, propertyName: property.name });
              }
            }
        });

        // Validate "Applies to" selection (Is any user group selected?)
        if (_ember['default'].isEmpty(rule.appliesTo)) errors.pushObject({ ruleId: rule.id, propertyName: 'applies_to' });

        // Validates "Action" selection ( Create / Update / Delete)
        if (!rule.runOnCreate && !rule.runOnUdate && !rule.runOnDelete) errors.pushObject({ ruleId: rule.id, propertyName: 'action' });

        // Validate Exemption User Groups
        if (rule.permissionGroupsExempt && _ember['default'].isEmpty(rule.exemptPermissionGroups)) {
          errors.push({ ruleId: rule.id, propertyName: 'exemptiongroup' });
        }
      });

      // If there are errors display an error message and highlight them
      if (!_ember['default'].isEmpty(errors)) {
        this._displayError(this.get('i18n').t('components.manage_resources.rules_form.flash_messages.error_saving').toString(), this.tabs.RULES);
        _ember['default'].run.later(this, '_highlightRulesErrors', errors, 200);
        return false;
      }

      return true;
    },

    _validateResourceRestrictionRules: function _validateResourceRestrictionRules(resourceRestrictions) {
      if (_ember['default'].isEmpty(resourceRestrictions)) {
        return this._displayError(this.get('i18n').t('components.manage_resources.rules_form.flash_messages.resource_restrictions_empty').toString(), this.tabs.RESTRICTIONS);
      }

      if (_ember['default'].isEmpty(resourceRestrictions.filterBy('isPrimary', true))) {
        return this._displayError(this.get('i18n').t('components.manage_resources.rules_form.flash_messages.resource_restrictions_no_primary').toString(), this.tabs.RESTRICTIONS);
      }

      var invalidMinMax = resourceRestrictions.filter(function (r) {
        return parseInt(r.get('min')) > parseInt(r.get('max')) || parseInt(r.get('max')) <= 0;
      });

      if (!_ember['default'].isEmpty(invalidMinMax)) {
        return this._displayError(this.get('i18n').t('components.manage_resources.rules_form.flash_messages.resource_restrictions_invalid_max').toString(), this.tabs.RESTRICTIONS);
      }
      return true;
    },

    // Highlight fields which are invalid
    _highlightRulesErrors: function _highlightRulesErrors(errors) {
      var groupClass = 'manageResources-itemForm-form-group';
      var itemClass = 'manageResources-itemForm-form-item';

      errors.forEach(function (error) {
        var groupObject = '.' + groupClass + '[data-rule-id=' + error.ruleId + ']';
        var itemObject = '.' + itemClass + '[data-property-name="' + error.propertyName + '"]';
        _ember['default'].$(groupObject + ' ' + itemObject).addClass('rule-property-invalid');
      });
    },

    _displayError: function _displayError(error, tabIndex) {
      var controller = this.controllerFor('manage-resources.booking-type.edit');
      controller.setProperties({
        error: error,
        selectedTab: tabIndex,
        isSaving: false
      });

      _ember['default'].$('.container-wrapper').scrollTop(0);

      return false;
    }

  });
});